import React, { useEffect, useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";
import axios from "axios";
import OutsideHandler from "../../components/OutsideHandler";
import axiosCMS from "../../libs/axios/axiosCMS";

const TCCFilterModal = ({ isOpen, onClose, onApply }) => {

  const [filters, setFilters] = useState({
    dashboard_type: { value: "", label: "ALL" },
    psm: { value: "", label: "ALL" },
    region: { value: "", label: "ALL" },
    estate: { value: "", label: "ALL" },
    division: { value: "", label: "ALL" },
    complex: { value: "", label: "ALL" },
    block: { value: "", label: "ALL" },
  });

  const [listPsm, setListPsm] = useState([{ value: "", label: "ALL" }]);
  const [listRegion, setListRegion] = useState([{ value: "", label: "ALL" }]);
  const [listEstate, setListEstate] = useState([{ value: "", label: "ALL" }]);
  const [listDivision, setListDivision] = useState([{ value: "", label: "ALL" },]);
  const [listComplex, setListComplex] = useState([{ value: "", label: "ALL" },]);

  const options = [
    { value: "", label: "ALL" },
    { value: "option_2", label: "Option 2" },
    { value: "option_3", label: "Option 3" },
  ];

  const dashboardTypeOptions = [
    { value: "", label: "ALL" },
    { value: "MVD Production", label: "MVD Production" },
    { value: "MVD Investor", label: "MVD Investor" },
    { value: "iCON Metrics SPB", label: "iCON Metrics SPB" },
    { value: "iCON IPD Effectiveness", label: "iCON IPD Effectiveness" },
    { value: "iCON Metrics Denda", label: "iCON Metrics Denda" },
    // Add more options as necessary
  ];

  const getListFilterData = async (url, params = null, setData) => {
    try {
      const response = await axiosCMS.get(url, {
        params: params
      });
      if (response.status === 200) {
        const data = response.data.data.map((item) => {
          return {
            value: item,
            label: item
          }
        })
        setData([{ value: "", label: "ALL" }, ...data])
      }
    } catch (error) {
      console.log("Failed to fetch data", error)
    }
  }
  
  useEffect(() => {
    getListFilterData('filter-location/psm', null, setListPsm);
  }, [])

  const handleSelectedFilter = async (key, selectedOptions) => {
    if (key === "psm") {
      const params = { psm: selectedOptions };
      const newFilters = {
        region: { value: "", label: "ALL" },
        estate: { value: "", label: "ALL" },
        division: { value: "", label: "ALL" },
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      }
      getListFilterData('filter-location/region', params, setListRegion);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters
      }));
      setListEstate([]);
      setListDivision([]);

    } else if (key === "region") {
      const params = {
        psm: filters.psm.value,
        region: selectedOptions
      };
      const newFilters = {
        estate: { value: "", label: "ALL" },
        division: { value: "", label: "ALL" },
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      }
      getListFilterData('filter-location/estate', params, setListEstate)
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters
      }));
    } else if (key === "estate") {
      const params = {
        psm: filters.psm.value,
        region: filters.region.value,
        estate: selectedOptions,
      };
      const newFilters = {
        division: { value: "", label: "ALL" },
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      };
      getListFilterData('filter-location/division', params, setListDivision)
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters
      }));

    } else if (key === "division") {
      const params = {
        psm: filters.psm.value,
        region: selectedOptions,
        estate: filters.estate.value,
        division: selectedOptions
      }
      const newFilters = {
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      }
      getListFilterData('filter-location/complex', params, setListComplex)
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters
      }));
    }
  }

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
    handleSelectedFilter(key.name, selectedOptions.value)
  };

  const handleSubmit = () => {
    const appliedFilters = {
      ...filters,
      dashboard_type: filters.dashboard_type?.value,
      psm: filters.psm?.value,
      region: filters.region?.value,
      estate: filters.estate?.value,
      division: filters.division?.value,
      complex: filters.complex?.value,
      block: filters.block?.value,
    };
    onApply(appliedFilters);
    onClose();
  };

  const handleClear = () => {
    setFilters({
      dashboard_type: { value: "", label: "ALL" },
      psm: { value: "", label: "ALL" },
      region: { value: "", label: "ALL" },
      estate: { value: "", label: "ALL" },
      division: { value: "", label: "ALL" },
      complex: { value: "", label: "ALL" },
      block: { value: "", label: "ALL" },
    });
    onApply({
      dashboard_type: "",
      psm: "",
      region: "",
      estate: "",
      division: "",
      complex: "",
      block: "",
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <OutsideHandler handleClose={() => onClose()}>
      <div className="tcc-filter__wrapper">
        <div className="tcc-filter__header">
          <h2 className="tcc-filter__title">Filter</h2>
          <button className="tcc-filter__close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="tcc-filter__separator" />
        <div className="tcc-filter__content">
          <div className="tcc-filter__form-group">
            <label htmlFor="dashboard_type">Dashboard Type</label>
            <Select
              id="dashboard_type"
              name="dashboard_type"
              options={dashboardTypeOptions}
              value={filters.dashboard_type}
              onChange={handleInputChange}
              placeholder="-- Select Dashboard Type --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
            />
          </div>
          <div className="tcc-filter__form-row">
            <div className="tcc-filter__form-group">
              <label htmlFor="psm">PSM</label>
              <Select
                id="psm"
                name="psm"
                options={listPsm}
                value={filters.psm}
                onChange={handleInputChange}
                placeholder="-- Select PSM --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
              />
            </div>
            <div className="tcc-filter__form-group">
              <label htmlFor="region">Region</label>
              <Select
                id="region"
                name="region"
                options={listRegion}
                value={filters.region}
                onChange={handleInputChange}
                placeholder="-- Select Region --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                isDisabled={filters.psm.value === ""}
              />
            </div>
          </div>
          <div className="tcc-filter__form-row">
            <div className="tcc-filter__form-group">
              <label htmlFor="estate">Estate</label>
              <Select
                id="estate"
                name="estate"
                options={listEstate}
                value={filters.estate}
                onChange={handleInputChange}
                placeholder="-- Select Estate --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                isDisabled={filters.region.value === ""}
              />
            </div>
            <div className="tcc-filter__form-group">
              <label htmlFor="division">Division</label>
              <Select
                id="division"
                name="division"
                options={listDivision}
                value={filters.division}
                onChange={handleInputChange}
                placeholder="-- Select Division --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                isDisabled={filters.estate.value === ""}
              />
            </div>
          </div>
          <div className="tcc-filter__form-row">
            <div className="tcc-filter__form-group">
              <label htmlFor="complex">Complex</label>
              <Select
                id="complex"
                name="complex"
                options={listComplex}
                value={filters.complex}
                onChange={handleInputChange}
                placeholder="-- Select Complex --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                isDisabled={filters.division.value === ""}
              />
            </div>
            <div className="tcc-filter__form-group">
              <label htmlFor="block">Block</label>
              <Select
                id="block"
                name="block"
                options={options}
                value={filters.block}
                onChange={handleInputChange}
                placeholder="-- Select Block --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                isDisabled={true}
              />
            </div>
          </div>
        </div>
        <div className="tcc-filter__footer">
          <button
            type="button"
            className="btn-tertiary-sm font-base cursor-pointer"
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            type="button"
            className="btn-primary-sm font-primary cursor-pointer"
            onClick={handleSubmit}
          >
            Apply
          </button>
        </div>
      </div>
    </OutsideHandler>
  );
};

export default TCCFilterModal;
