import { useCallback, useEffect, useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import axios from "axios";
import LoadingComponent from "../../components/Loading";
import { millisecondsToMinutes, millisecondsToSeconds } from "date-fns";
import debounce from "../../utils/Debounce";
import axiosCMS from "../../libs/axios/axiosCMS";

const ChurnRate = ({ isOpen, onClose, selectedFilters }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const rowsPerPage = 5;

  const indexOfLastData = currentPage * rowsPerPage;
  const indexOfFirstData = indexOfLastData - rowsPerPage;

  const getData = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    try {
      const URL = 'user-engagement/churn_user_detail';
      const response = await axiosCMS.post(URL, filters);
      const result = response.data;
      setData(result.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    setIsLoading(false)
  }, 1500), []);

  useEffect(() => {
    getData(selectedFilters);
  }, [selectedFilters]);

  const dataPaginator = () => {
    const paginated = data.slice(indexOfFirstData, indexOfLastData);
    setPaginatedData(paginated);
  }

  useEffect(() => {
    dataPaginator();
  }, [currentPage, data]);

  const paginate = (direction) => {
    if (direction === 'next' && currentPage < Math.ceil(data.length / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  if (!isOpen) return null;

  return (
    <div className={`ue-churn-rate ${isOpen ? "open" : ""}`}>
      <div className="ue-churn-rate__content">
        <div className="ue-churn-rate__header">
          <h1 className="ue-churn-rate__title">Churned Users</h1>
          <button className="ue-churn-rate__btn-close" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" />
          </button>
        </div>
        <div className="ue-churn-rate__separator" />
        <div className="ue-churn-rate__section">
          <div className="ue-churn-rate__box">
            <table className="ue-churn-rate__table">
              <thead>
                <tr>
                  <th>Email</th>
                  {/* <th>Last Activity Date</th> */}
                  <th>Avg. Duration/Session</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {isLoading
                  ? <tr>
                    <td colSpan={3}><LoadingComponent message={'Loading data...'} /></td>
                  </tr>
                  : paginatedData.length !== 0
                    ? paginatedData.map((value, index) => (
                      <tr key={index}>
                        <td>{value.email}</td>
                        {/* <td>{value.last_chat}</td> */}
                        <td>{value.avg_duration_session >= 60000 ? millisecondsToMinutes(value.avg_duration_session) : millisecondsToSeconds(value.avg_duration_session)}<span> {value.avg_duration_session > 60000 ? 'minutes' : 'seconds'}</span></td>
                        <td>{value.role?.name}</td>
                      </tr>
                    ))
                    : <tr>
                      <td colSpan={3} className="text-center">No data found</td>
                    </tr>
                }
              </tbody>
            </table>
            <div className="ue-churn-rate__pagination">
              <button onClick={() => paginate('prev')} disabled={currentPage === 1}>Previous</button>
              <button onClick={() => paginate('next')} disabled={currentPage === Math.ceil(data.length / rowsPerPage)}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default ChurnRate