import { roleef, useState } from "react";
import OutsideHandler from "../../components/OutsideHandler";
import Select from "react-select";

import closeIcon from "../../assets/cancelIcon.svg";

const FilterModal = ({ isOpen, onApply, onFilterModal }) => {

  const [menuOptions, setMenuOptions] = useState([]);

  const [filters, setFilters] = useState({
    menu: { label: "ALL", value: "" },
  })

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  }

  const handleClear = () => {
    onApply();
    onFilterModal(false);
  }

  const handleApply = () => {
    onApply(filters);
    onFilterModal(false);
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onFilterModal(false)}>
      <div className="privilege-filter__wrapper">
        <div className="privilege-filter__header">
          <h2 className="privilege-filter__title">Filter</h2>
          <button className="privilege-filter__close" onClick={() => onFilterModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="privilege-filter__separator" />
        <div className="privilege-filter__content">
          <div className="privilege-filter__form-group">
            <label htmlFor="privilege_name" className="privilege-filter__input-label">Menu</label>
            <Select
              id="menu"
              name="menu"
              options={menuOptions}
              value={filters.menu}
              onChange={handleInputChange}
              placeholder="-- Select Menu --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="privilege-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;