import { useCallback, useEffect, useState } from "react";
import LoadingOverview from "../../components/LoadingOverview";
import axios from "axios";
import debounce from "../../utils/Debounce";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axiosCMS from "../../libs/axios/axiosCMS";

const TopQueries = ({ selectedFilters, selectedMonth, selectedYear }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const getTopQueries = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    try {
      const URL = "user-engagement/top-queries"
      const response = await axiosCMS.post(URL, filters)
      if (response.status === 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (err) {
      console.error('Error fetching top queries', err);
    }
    setIsLoading(false);
  }, 1500), [])

  useEffect(() => {
    getTopQueries(selectedFilters);
  }, [selectedFilters])

  return (
    <div className="ue-section w-400">
      <h3 className="section-title">
        Top Queries
        <img
          src={tooltipIcon}
          alt="tooltip icon"
          className="tooltip-icon anchor-top-queries"
        />
        <ReactTooltip
          id='tooltip'
          place="top"
          content="Most frequently queried Metric, Location, Month and  Year by users"
          anchorSelect=".anchor-top-queries"
          style={{ maxWidth: "150px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
        />
      </h3>

      {
        isLoading
          ? <>
            <LoadingOverview length={2} prefixCSS={'ue'} />
            <div className="mt-8"></div>
            <LoadingOverview length={2} prefixCSS={'ue'} />
          </>
          : (
            <>
              <div className="ue-boxes">
                <div className="ue-top-queries-box">
                  <p className="box-title">
                    Top Metric Queried
                  </p>
                  <p className="box-value-sm">
                    {data.top_metric}
                  </p>
                  <p className="box-date">
                  </p>
                </div>
                <div className="ue-top-queries-box">
                  <p className="box-title">
                    Top Location
                  </p>
                  <p className="box-value-sm">
                    {data.top_location}
                  </p>
                  <p className="box-date">
                  </p>
                </div>
              </div>
              <div className="ue-boxes mt-8">
                <div className="ue-top-queries-box">
                  <p className="box-title">
                    Top Month Queried
                  </p>
                  <p className="box-value-sm">
                    {data.top_month}
                  </p>
                  <p className="box-date">
                  </p>
                </div>
                <div className="ue-top-queries-box">
                  <p className="box-title">
                    Top Year Queried
                  </p>
                  <p className="box-value-sm">
                    {data.top_year}
                  </p>
                  <p className="box-date">
                  </p>
                </div>
              </div>
            </>
          )
      }


    </div>
  )
}

export default TopQueries;