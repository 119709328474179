import { useEffect, useState } from "react";
import Select from "react-select";

import closeIcon from "../../assets/cancelIcon.svg";
import InputErrorMessage from "../../components/InputErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";

const AddUserModal = ({ isOpen, onAddUserModal }) => {
  const { register, reset, watch, setValue, control, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ]

  const cmsAccessOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' }
  ]

  const cmsAccessWatch = watch("cms_access", cmsAccessOptions[1]);

  const [role, setRole] = useState(null);
  const [status, setStatus] = useState(statusOptions[0]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [cmsPermissionOptions, setCmsPermissionOptions] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = (data) => {
    console.log(data);
    reset();
  }

  if (!isOpen) return null;
  return (
    <div className="user-add__overlay">
      <div className="user-add__modal">
        <div className="user-add__header">
          <h2 className="user-add__title">Add User</h2>
          <button className="user-add__close" onClick={() => onAddUserModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="user-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="user-add__content">
                  <h3 className="user-add__confirmation-title">Do you really wish to submit new user?</h3>
                  <p className="user-add__confirmation-desc">Once you submit, the new user will be added to the system.</p>
                  <div className="user-add__footer">
                    <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button type="submit" className="btn-primary font-primary" >Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="user-add__content">
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="name">Name <span className="user-add__input-required">*</span></label>
                    <input
                      className="user-add__input-box"
                      {...register("name", {
                        required: "Name must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="email">Email <span className="user-add__input-required">*</span></label>
                    <input
                      type="text"
                      className="user-add__input-box"
                      {...register("email", {
                        required: "Email must be filled",
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Email format doesn't recognized "
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="cms_access">CMS Access <span className="user-add__input-required">*</span></label>
                    <Controller
                      control={control}
                      name="cms_access"
                      rules={{
                        required: "CMS Access must be filled",
                      }}
                      defaultValue={cmsAccessOptions[1]}
                      render={({ field }) => (
                        <Select
                          id="cms_access"
                          name="cms_access"
                          {...field}
                          options={cmsAccessOptions}
                          placeholder="-- Select CMS Access --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="cms_access"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  {
                    cmsAccessWatch.value === 'yes' && (
                      <div className="user-add__form-group">
                        <label className="user-add__input-label" htmlFor="cms_permission">CMS Permission <span className="user-add__input-required">*</span></label>
                        <Controller
                          control={control}
                          name="cms_permission"
                          rules={{
                            required: "CMS Permission must be filled",
                          }}
                          defaultValue={cmsPermissionOptions[1]}
                          render={({ field }) => (
                            <Select
                              id="cms_permission"
                              name="cms_permission"
                              {...field}
                              options={cmsPermissionOptions}
                              placeholder="-- Select CMS Permission --"
                              className="multi-select"
                              classNamePrefix="select"
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="cms_permission"
                          render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                              <InputErrorMessage key={type} message={message} />
                            ))
                          }
                        />
                      </div>
                    )
                  }
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="role">Role <span className="user-add__input-required">*</span></label>
                    <Controller
                      control={control}
                      name="role"
                      rules={{
                        required: "Role must be filled",
                      }}
                      defaultValue={role}
                      render={({ field }) => (
                        <Select
                          id="role"
                          name="role"
                          {...field}
                          options={roleOptions}
                          placeholder="-- Select Role --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="role"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__form-group">
                    <label className="user-add__input-label" htmlFor="status">Status</label>
                    <Controller
                      control={control}
                      name="status"
                      rules={{
                        required: "Status must be filled",
                      }}
                      defaultValue={status}
                      render={({ field }) => (
                        <Select
                          id="status"
                          name="status"
                          {...field}
                          options={statusOptions}
                          placeholder="-- Select Status --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="status"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="user-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => onAddUserModal(false)}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default AddUserModal;