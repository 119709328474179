import { useRef, useState } from "react";
import OutsideHandler from "../../components/OutsideHandler";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";

const FilterModal = ({ isOpen, onFilterModal, onApply }) => {
  const ref = useRef();
  const [roleOptions, setRoleOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { label: "ALL", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [cmsAccessOptions, setCmsAccessOptions] = useState([]);

  const [filters, setFilters] = useState({
    role: { label: "ALL", value: "" },
    status: { label: "ALL", value: "" },
    privilege: { label: "ALL", value: "" },
    cms_access: { label: "ALL", value: "" },
  })

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  };

  const handleClear = () => {
    onApply({
      role: "",
      status: "",
      privilege: "",
      cms_access: "",
    });
    setFilters({
      role: { label: "ALL", value: "" },
      status: { label: "ALL", value: "" },
      privilege: { label: "ALL", value: "" },
      cms_access: { label: "ALL", value: "" },
    });
    onFilterModal(false);
  }

  const handleApply = () => {
    onApply({
      role: filters.role.value,
      status: filters.status.value,
      privilege: filters.privilege.value,
      cms_access: filters.cms_access.value,
    });
    onFilterModal(false);
  }

  if (!isOpen) return null;
  return (
    <OutsideHandler handleClose={() => onFilterModal(false)}>
      <div className="user-filter__wrapper">
        <div className="user-filter__header">
          <h2 className="user-filter__title">Filter</h2>
          <button className="user-filter__close" onClick={() => onFilterModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <hr className="user-filter__separator" />
        <div className="user-filter__content">
          <div className="user-filter__form-row">
            <div className="user-filter__form-group">
              <label htmlFor="role" className="user-filter__input-label">Role</label>
              <Select
                id="role"
                name="role"
                options={roleOptions}
                value={filters.role}
                onChange={handleInputChange}
                placeholder="-- Select Role --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
            <div className="user-filter__form-group">
              <label htmlFor="status" className="user-filter__input-label">Status</label>
              <Select
                id="status"
                name="status"
                options={statusOptions}
                value={filters.status}
                onChange={handleInputChange}
                placeholder={"-- Select Region --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
          </div>
          <div className="user-filter__form-row">
            <div className="user-filter__form-group">
              <label htmlFor="privilege" className="user-filter__input-label">Privilege</label>
              <Select
                id="privilege"
                name="privilege"
                options={privilegeOptions}
                value={filters.privilege}
                onChange={handleInputChange}
                placeholder="-- Select Role --"
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
            <div className="user-filter__form-group">
              <label htmlFor="cms_access" className="user-filter__input-label">CMS Access</label>
              <Select
                id="cms_access"
                name="cms_access"
                options={cmsAccessOptions}
                value={filters.cms_access}
                onChange={handleInputChange}
                placeholder={"-- Select Region --"}
                className="multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
              />
            </div>
          </div>
        </div>
        <div className="user-filter__footer">
          <button className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>Clear</button>
          <button className="btn-primary-sm font-primary cursor-pointer" onClick={handleApply}>Apply</button>
        </div>
      </div>
    </OutsideHandler>
  )
}

export default FilterModal;