import { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputErrorMessage from "../../components/InputErrorMessage";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import { ReactComponent as Checkmark } from "../../assets/checkIcon16.svg";
import { ReactComponent as Uncheckmark } from "../../assets/minusIcon16.svg";
import chevronDown from "../../assets/chevronDownIcon24.svg";
import axiosCMS from "../../libs/axios/axiosCMS";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon20.svg";
import { ReactComponent as ClearIcon } from "../../assets/xIcon20.svg";
import Select from "react-select";

const EditRoleModal = ({ role, onEditRoleModal, defaultPSMAccess, defaultRegionAccess, defaultEstateAccess, dashboardAccess, getRoles }) => {

  const { register, control, reset, clearErrors, setError, setValue, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [psmList, setPsmList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [estateList, setEstateList] = useState([]);
  const [selectedPSM, setSelectedPSM] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedEstate, setSelectedEstate] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState([]);

  const [selectedAllAccess, setSelectedAllAccess] = useState(false);
  const [selectedAllPSM, setSelectedAllPSM] = useState(false);
  const [selectedAllRegion, setSelectedAllRegion] = useState(false);
  const [selectedAllEstate, setSelectedAllEstate] = useState(false);
  const [selectedAllDashboard, setSelectedAllDashboard] = useState(false);

  const [searchPSM, setSearchPSM] = useState('');
  const [searchRegion, setSearchRegion] = useState('');
  const [searchEstate, setSearchEstate] = useState('');
  const [searchDashboard, setSearchDashboard] = useState('');

  const [isActiveOptions, setIsActiveOptions] = useState([
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ]);

  const [isOpenAccordian, setIsOpenAccordian] = useState('');
  const [isOpenDashboardAccordian, setIsOpenDashboardAccordian] = useState(true);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const storageDataAccessRef = useRef({});

  const getRoleAccessDetail = async (accessMasterId, groupName) => {
    try {
      const response = await axiosCMS.get('role/get-data-access-detail', {
        params: {
          access_master_id: accessMasterId,
          group: groupName,
        }
      })

      const result = response.data;
      storageDataAccessRef.current[accessMasterId] = result.data;
      return result.data;
    } catch (error) {
      console.error("Error fetching data access detail: ", error);
    }

  };

  const setAutoChecked = (groupName, result, currentSelected, setSelectedData, listData, setListData, isResetList) => {
    const groupIndex = result.findIndex((obj) => obj.group_name === groupName);
    if (currentSelected.length === 0) {
      setSelectedData(result[groupIndex].list_group);
      setListData(listData.filter((obj) => result[groupIndex].list_group.findIndex((item) => item.name === obj.name) !== -1));
    } else {
      const updatedSelectedData = [...currentSelected, ...result[groupIndex].list_group].filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      const updatedListData = isResetList ? listData : listData.filter((obj) => updatedSelectedData.findIndex((item) => item.name === obj.name) !== -1)
      setSelectedData(updatedSelectedData);
      setListData(updatedListData);
    }
  }

  const setAutoUnchecked = (groupName, result, currentSelected, setSelectedData, listData, setListData, isResetList) => {
    const groupIndex = result.findIndex((obj) => obj.group_name === groupName);
    const updatedSelectedData = currentSelected.filter((obj) => result[groupIndex].list_group.findIndex((item) => item.name === obj.name) === -1);
    const updatedListData = isResetList ? listData : listData.filter((obj) => updatedSelectedData.findIndex((item) => item.name === obj.name) !== -1);
    setSelectedData(updatedSelectedData);
    setListData(updatedListData);
  }

  const handleOnChangeCheck = async (e, data, groupName, currentChecked, setChecked) => {
    const { id, name, access_master_id } = data;
    const isChecked = e.target.checked;
    if (isChecked) {
      clearErrors("data_access");
      const updatedListChecked = [...currentChecked, data]
      setChecked(updatedListChecked);
      if (groupName === 'PSM') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('REGION', result, selectedRegion, setSelectedRegion, defaultRegionAccess, setRegionList);
        setAutoChecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList);
      } else if (groupName === 'REGION') {
        const result = storageDataAccessRef.current[access_master_id] || await getRoleAccessDetail(access_master_id, groupName);
        setAutoChecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList);
      }
    } else {
      const updatedListChecked = currentChecked.filter((obj) => obj.name !== name);
      setChecked(updatedListChecked);
      if (groupName === 'PSM') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('REGION', result, selectedRegion, setSelectedRegion, defaultRegionAccess, setRegionList, updatedListChecked.length === 0);
        setAutoUnchecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList, updatedListChecked.length === 0);
      } else if (groupName === 'REGION') {
        const result = storageDataAccessRef.current[access_master_id] ?? await getRoleAccessDetail(access_master_id, groupName);
        setAutoUnchecked('ESTATE', result, selectedEstate, setSelectedEstate, defaultEstateAccess, setEstateList, updatedListChecked.length === 0);
      }
    }
  }

  const handleSelectAllAccess = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedPSM(psmList);
      setSelectedRegion(regionList);
      setSelectedEstate(estateList);
      clearErrors("data_access");
    } else {
      setSelectedPSM([]);
      setSelectedRegion([]);
      setSelectedEstate([]);
    }
    setSelectedAllAccess(isChecked);
    setSelectedAllPSM(isChecked);
    setSelectedAllRegion(isChecked);
    setSelectedAllEstate(isChecked);
  }

  const handleSelectAllSub = (e, targetSelected, listSelected, setChecked) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      targetSelected(listSelected);
      clearErrors("data_access");
    } else {
      targetSelected([]);
    }
    setChecked(isChecked);
  }

  const handleChangeDashboard = (e, data) => {
    if (e.target.checked) {
      setSelectedDashboard([...selectedDashboard, data]);
      clearErrors("dashboard_type");
    } else {
      setSelectedDashboard(selectedDashboard.filter((obj) => obj.id !== data.id));
    }
  }

  const handleSelectAllDashboard = (e) => {
    if (e.target.checked) {
      setSelectedDashboard(dashboardAccess);
      setSelectedAllDashboard(true);
      clearErrors("dashboard_type");
    } else {
      setSelectedDashboard([]);
      setSelectedAllDashboard(false);
    }
  }

  const postEditRole = async (data, id) => {
    const URL = "role/update-role/";
    try {
      const response = await axiosCMS.post(URL + id, data);
      if (response.status === 200) {
        dispatch(addToast({
          type: 'success',
          title: 'Changes were successfully saved'
        }))
        onEditRoleModal(null);
        setIsConfirmationOpen(false);
        getRoles();
      }
    } catch (error) {
      console.error("Error adding role: ", error);
      dispatch(addToast({
        type: 'error',
        title: 'Error submitting role'
      }))
    }
  }

  const handleConfirmation = (action = null) => {
    let isValid = true;
    let message = '';
    if (selectedPSM.length === 0 && selectedRegion.length === 0 && selectedEstate.length === 0) {
      message = "Data access must be filled";
      isValid = false;
    } else if (selectedPSM.length >= 1 && selectedRegion.length === 0 && selectedEstate.length === 0) {
      message = "Region and Estate must be filled";
      isValid = false;
    } else if (selectedRegion.length >= 1 && selectedEstate.length === 0) {
      message = "Estate must be filled";
      isValid = false;
    }
    if (!isValid) {
      setError("data_access", {
        types: {
          required: message,
        }
      });
      setIsOpenAccordian('');
      return;
    }

    if (selectedDashboard.length === 0) {
      setError("dashboard_type", {
        types: {
          required: "Dashboard type must be filled",
        }
      });
      return;
    }
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }


  const handleFinalSubmit = (form) => {
    setIsLoading(true);
    const psm = selectedPSM.map((obj) => obj.id);
    const region = selectedRegion.map((obj) => obj.id);
    const estate = selectedEstate.map((obj) => obj.id);
    const dashboardType = selectedDashboard.map((obj) => obj.id);
    const data = {
      role_name: form.name,
      data_access: [...psm, ...region, ...estate],
      dashboard_type: dashboardType
    }
    postEditRole(data, role.id)
    setIsLoading(false);
  }

  const handleAccordian = (type) => {
    setIsOpenAccordian(isOpenAccordian === type ? '' : type);
  }

  const getRoleById = async (id) => {
    console.log('isopen');
    const URL = "role/get-role/";
    try {
      const response = await axiosCMS.get(URL + id);
      const result = response.data;
      if (response.status === 200) {
        setValue('name', result.data.role.role_name);
        setValue('is_active', result.data.is_active === 'active' ? isActiveOptions[0] : isActiveOptions[1]);
        const currentSelectedPSM = result.data.data_access.findIndex((obj) => obj.group_name === 'PSM');
        const currentSelectedRegion = result.data.data_access.findIndex((obj) => obj.group_name === 'REGION');
        const currentSelectedEstate = result.data.data_access.findIndex((obj) => obj.group_name === 'ESTATE');
        currentSelectedPSM !== -1 ? setSelectedPSM(defaultPSMAccess.filter((obj) => result.data.data_access[currentSelectedPSM].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedPSM([]);
        currentSelectedRegion !== -1 ? setSelectedRegion(defaultRegionAccess.filter((obj) => result.data.data_access[currentSelectedRegion].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedRegion([]);
        currentSelectedEstate !== -1 ? setSelectedEstate(defaultEstateAccess.filter((obj) => result.data.data_access[currentSelectedEstate].list_group.findIndex((item) => item.name === obj.name) !== -1)) : setSelectedEstate([]);

        setSelectedDashboard(result.data.dashboard_type.map((obj) => ({ id: obj.dashboard_id, dashboard_name: obj.dashboard_name })));
      }
    } catch (error) {
      console.log('Failed to get dashboard data', error);
    }
  }

  useEffect(() => {
    if (role !== null) {
      getRoleById(role.id);
    }
  }, [role]);

  useEffect(() => {
    setPsmList(defaultPSMAccess);
    setRegionList(defaultRegionAccess);
    setEstateList(defaultEstateAccess);
  }, [defaultEstateAccess, defaultPSMAccess, defaultRegionAccess]);


  if (role === null) return null;
  return (
    <div className="role-add__overlay">
      <div className="role-add__modal">
        <div className="role-add__header">
          <h2 className="role-add__title">Edit Role</h2>
          <button className="role-add__close" onClick={() => onEditRoleModal(null)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="role-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="role-add__content">
                  <h3 className="role-add__confirmation-title">Do you really wish to submit edited role?</h3>
                  <p className="role-add__confirmation-desc">Once you submit, the edited role will be updated to the system.</p>
                  <div className="role-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => handleConfirmation(null)}>Cancel</button>
                    <button type="submit" className="btn-primary font-primary">Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="role-add__content">
                  <div className="role-add__form-group">
                    <label className="role-add__input-label" htmlFor="name">Role Name <span className="role-add__input-required">*</span></label>
                    <input type="text" className="role-add__input-box"
                      {...register("name", {
                        required: "Role name must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label className="role-add__input-label">Data Access</label>
                    <div className="role-add__accordian">
                      <div className="role-add__accordian-header">
                        <label className='role-add__form-checkbox'>
                          <input type='checkbox'
                            onChange={handleSelectAllAccess}
                            checked={selectedAllAccess}
                          />
                          <div className='role-add__checkmark'>
                            <Checkmark className="role-add__checkmark-icon" />
                            <Uncheckmark className="role-add__checkmark-icon" />
                          </div>
                          ALL ACCESS
                        </label>
                      </div>
                      <div className="role-add__accordian-separator">
                      </div>
                      <div className="role-add__accordian-separator">
                        <div className="role-add__accordian-header">
                          <label className='role-add__form-checkbox'>
                            <input type='checkbox'
                              onChange={(e) => handleSelectAllSub(e, setSelectedPSM, psmList, setSelectedAllPSM)}
                              checked={selectedAllPSM}
                            />
                            <div className='role-add__checkmark'>
                              <Checkmark className="role-add__checkmark-icon" />
                            </div>
                            PSM
                          </label>
                          <img src={chevronDown} onClick={() => handleAccordian('PSM')} alt="chevron-down" className={`role-add__accordian-icon cursor-pointer ${isOpenAccordian === 'PSM' ? 'rotate' : ''}`} />
                        </div>
                        <div className={`role-add__accordian-wrapper ${isOpenAccordian === 'PSM' ? 'show' : ''}`}>
                          <div className="role-add__accordian-content">
                            <div className="role-add__accordian-search-wrapper">
                              <input type="text" placeholder="Search" className="role-add__accordian-search" value={searchPSM} onChange={(e) => setSearchPSM(e.target.value)} />
                              {searchPSM
                                ? <ClearIcon className="role-add__accordian-search-icon" onClick={() => setSearchPSM('')} />
                                : <SearchIcon className="role-add__accordian-search-icon" />
                              }
                            </div>
                            <ul className="role-add__accordian-list">
                              {psmList.length > 0
                                ? searchPSM
                                  ? psmList.filter((obj) => obj.name.toLowerCase().includes(searchPSM.toLowerCase())).map((access_list) => (
                                    <li key={access_list.id}>
                                      <label className='role-add__form-checkbox'>
                                        <input
                                          type='checkbox'
                                          onChange={(e) => handleOnChangeCheck(e, access_list, 'PSM', selectedPSM, setSelectedPSM)}
                                          checked={selectedPSM.findIndex(obj => obj.name === access_list.name) !== -1}
                                        // disabled={selectedMonth.some(obj => obj.id === access_list.id)}
                                        />
                                        <span className='role-add__checkmark'>
                                          <Checkmark className={`role-add__checkmark-icon `} />
                                        </span>
                                        {access_list.name}
                                      </label>
                                    </li>
                                  ))
                                  : psmList.map((access_list) => (
                                    <li key={access_list.id}>
                                      <label className='role-add__form-checkbox'>
                                        <input
                                          type='checkbox'
                                          onChange={(e) => handleOnChangeCheck(e, access_list, 'PSM', selectedPSM, setSelectedPSM)}
                                          checked={selectedPSM.findIndex(obj => obj.name === access_list.name) !== -1}
                                        // disabled={selectedMonth.some(obj => obj.id === access_list.id)}
                                        />
                                        <span className='role-add__checkmark'>
                                          <Checkmark className={`role-add__checkmark-icon `} />
                                        </span>
                                        {access_list.name}
                                      </label>
                                    </li>
                                  ))
                                : <li>No data</li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="role-add__accordian-separator">
                        <div className="role-add__accordian-header">
                          <label className='role-add__form-checkbox'>
                            <input type='checkbox'
                              onChange={(e) => handleSelectAllSub(e, setSelectedRegion, regionList, setSelectedAllRegion)}
                              checked={selectedAllRegion}
                            />
                            <div className='role-add__checkmark'>
                              <Checkmark className="role-add__checkmark-icon" />
                            </div>
                            REGION
                          </label>
                          <img src={chevronDown} onClick={() => handleAccordian('REGION')} alt="chevron-down" className={`role-add__accordian-icon cursor-pointer ${isOpenAccordian === 'REGION' ? 'rotate' : ''}`} />
                        </div>
                        <div className={`role-add__accordian-wrapper ${isOpenAccordian === 'REGION' ? 'show' : ''}`}>
                          <div className="role-add__accordian-content">
                            <div className="role-add__accordian-search-wrapper">
                              <input
                                type="text"
                                placeholder="Search"
                                className="role-add__accordian-search"
                                value={searchRegion}
                                onChange={(e) => setSearchRegion(e.target.value)}
                              />
                              {searchRegion
                                ? <ClearIcon className="role-add__accordian-search-icon" onClick={() => setSearchRegion('')} />
                                : <SearchIcon className="role-add__accordian-search-icon" />
                              }
                            </div>
                            <ul className="role-add__accordian-list">
                              {regionList.length > 0
                                ? searchRegion
                                  ? regionList.filter((obj) => obj.name.toLowerCase().includes(searchRegion.toLowerCase())).map((access_list) => (
                                    <li key={access_list.id}>
                                      <label className='role-add__form-checkbox'>
                                        <input type='checkbox'
                                          onChange={(e) => handleOnChangeCheck(e, access_list, 'REGION', selectedRegion, setSelectedRegion)}
                                          checked={selectedRegion.findIndex(obj => obj.name === access_list.name) !== -1}
                                        // disabled={selectedPSM.length >= 1}
                                        />
                                        <span className='role-add__checkmark'>
                                          <Checkmark className={`role-add__checkmark-icon ${selectedPSM.length >= 1 ? 'role-add__checkmark-icon--black' : ''}`} />
                                        </span>
                                        {access_list.name}
                                      </label>
                                    </li>
                                  ))
                                  : regionList.map((access_list) => (
                                    <li key={access_list.id}>
                                      <label className='role-add__form-checkbox'>
                                        <input type='checkbox'
                                          onChange={(e) => handleOnChangeCheck(e, access_list, 'REGION', selectedRegion, setSelectedRegion)}
                                          checked={selectedRegion.findIndex(obj => obj.name === access_list.name) !== -1}
                                        // disabled={selectedPSM.length >= 1}
                                        />
                                        <span className='role-add__checkmark'>
                                          <Checkmark className={`role-add__checkmark-icon ${selectedPSM.length >= 1 ? 'role-add__checkmark-icon--black' : ''}`} />
                                        </span>
                                        {access_list.name}
                                      </label>
                                    </li>
                                  ))
                                : <li>No data</li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="role-add__accordian-separator">
                        <div className="role-add__accordian-header">
                          <label className='role-add__form-checkbox'>
                            <input type='checkbox'
                              onChange={(e) => handleSelectAllSub(e, setSelectedEstate, estateList, setSelectedAllEstate)}
                              checked={selectedAllEstate}
                            />
                            <div className='role-add__checkmark'>
                              <Checkmark className="role-add__checkmark-icon" />
                            </div>
                            ESTATE
                          </label>
                          <img src={chevronDown} onClick={() => handleAccordian('ESTATE')} alt="chevron-down" className={`role-add__accordian-icon cursor-pointer ${isOpenAccordian === 'ESTATE' ? 'rotate' : ''}`} />
                        </div>
                        <div className={`role-add__accordian-wrapper ${isOpenAccordian === 'ESTATE' ? 'show' : ''}`}>
                          <div className="role-add__accordian-content">
                            <div className="role-add__accordian-search-wrapper">
                              <input type="text" placeholder="Search" className="role-add__accordian-search" value={searchEstate} onChange={(e) => setSearchEstate(e.target.value)} />
                              {searchEstate
                                ? <ClearIcon className="role-add__accordian-search-icon" onClick={() => setSearchEstate('')} />
                                : <SearchIcon className="role-add__accordian-search-icon" />
                              }
                            </div>
                            <ul className="role-add__accordian-list">
                              {estateList.length > 0
                                ? searchEstate
                                  ? estateList.filter((obj) => obj.name.toLowerCase().includes(searchEstate.toLowerCase())).map((access_list) => (
                                    <li key={access_list.id}>
                                      <label className='role-add__form-checkbox'>
                                        <input type='checkbox'
                                          onChange={(e) => handleOnChangeCheck(e, access_list, 'ESTATE', selectedEstate, setSelectedEstate)}
                                          checked={selectedEstate.findIndex(obj => obj.name === access_list.name) !== -1}
                                        // disabled={selectedPSM.length >= 1 || selectedRegion.length >= 1}
                                        />
                                        <span className='role-add__checkmark'>
                                          <Checkmark className={`role-add__checkmark-icon `} />
                                        </span>
                                        {access_list.name}
                                      </label>
                                    </li>
                                  ))
                                  : estateList.map((access_list) => (
                                    <li key={access_list.id}>
                                      <label className='role-add__form-checkbox'>
                                        <input type='checkbox'
                                          onChange={(e) => handleOnChangeCheck(e, access_list, 'ESTATE', selectedEstate, setSelectedEstate)}
                                          checked={selectedEstate.findIndex(obj => obj.name === access_list.name) !== -1}
                                        // disabled={selectedPSM.length >= 1 || selectedRegion.length >= 1}
                                        />
                                        <span className='role-add__checkmark'>
                                          <Checkmark className={`role-add__checkmark-icon `} />
                                        </span>
                                        {access_list.name}
                                      </label>
                                    </li>
                                  ))
                                : <li>No data</li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="data_access"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label className="role-add__input-label" htmlFor="name">Dashboard Type</label>
                    <div className="role-add__accordian">
                      <div className="role-add__accordian-header">
                        <label className='role-add__form-checkbox'>
                          <input type='checkbox'
                            onChange={handleSelectAllDashboard}
                            checked={selectedAllDashboard}
                          />
                          <div className='role-add__checkmark'>
                            <Checkmark className="role-add__checkmark-icon" />
                          </div>
                          ALL
                        </label>
                        <img src={chevronDown} onClick={() => setIsOpenDashboardAccordian(!isOpenDashboardAccordian)} alt="chevron-down" className={`role-add__accordian-icon cursor-pointer ${isOpenDashboardAccordian ? 'rotate' : ''}`} />
                      </div>
                      <div className={`role-add__accordian-wrapper ${isOpenDashboardAccordian ? 'show' : ''}`}>
                        <div className={`role-add__accordian-content `}>
                          <div className="role-add__accordian-search-wrapper">
                            <input type="text" placeholder="Search by Role Name" className="role-add__accordian-search" value={searchDashboard} onChange={(e) => setSearchDashboard(e.target.value)} />
                            {searchDashboard
                              ? <ClearIcon className="role-add__accordian-search-icon" onClick={() => setSearchDashboard('')} />
                              : <SearchIcon className="role-add__accordian-search-icon" />
                            }
                          </div>
                          <ul className="role-add__accordian-list">
                            {searchDashboard
                              ? dashboardAccess.filter((obj) => obj.dashboard_name.toLowerCase().includes(searchDashboard.toLowerCase())).map((obj) => (
                                <li key={obj.id}>
                                  <label className='role-add__form-checkbox'>
                                    <input type='checkbox'
                                      onChange={(e) => handleChangeDashboard(e, obj)}
                                      checked={selectedDashboard.some(item => item.id === obj.id)}
                                    />
                                    <span className='role-add__checkmark'>
                                      <Checkmark className="role-add__checkmark-icon" />
                                    </span>
                                    {obj.dashboard_name}
                                  </label>
                                </li>
                              ))
                              : dashboardAccess.map((obj) => (
                                <li key={obj.id}>
                                  <label className='role-add__form-checkbox'>
                                    <input type='checkbox'
                                      onChange={(e) => handleChangeDashboard(e, obj)}
                                      checked={selectedDashboard.some(item => item.id === obj.id)}
                                    />
                                    <span className='role-add__checkmark'>
                                      <Checkmark className="role-add__checkmark-icon" />
                                    </span>
                                    {obj.dashboard_name}
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="dashboard_type"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__form-group">
                    <label htmlFor="is_active" className="role-add__input-label">Status</label>
                    <Controller
                      control={control}
                      name="is_active"
                      rules={{ required: "Status must be filled" }}
                      defaultValue={isActiveOptions[0]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={isActiveOptions}
                          placeholder="-- Select Status --"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="is_active"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="role-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => onEditRoleModal(null)}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default EditRoleModal;