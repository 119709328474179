import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import kebabMenu from "../../assets/kebabMenu.svg";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";
import { Alert, Pagination } from "../../components";
import EditGeneralProp from "./EditGeneralProp";
import { ReactComponent as DetailsIcon } from "../../assets/detailsEye.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import loadingIcon from "../../assets/loadingIcon.svg";
import ManualPagination from "../../components/ManualPagination";
import LoadingComponent from "../../components/Loading";
import debounce from "../../utils/Debounce";
import OutsideHandler from "../../components/OutsideHandler";
import { convertFormatedDate } from "../../utils/FunctionHelpers";
import axiosCMS from "../../libs/axios/axiosCMS";
import LoadingTable from "../../components/LoadingTable";

const GeneralProperties = ({ currentPage, setCurrentPage }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataGeneralProps, setDataGeneralProps] = useState([]);
  const [paginatedDataGeneralProps, setPaginatedDataGeneralProps] = useState([]);
  const [dataGeneralProp, setDataGeneralProp] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [successNotification, setSuccessNotification] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSort, setSelectedSort] = useState({
    order_by: "updated_at",
    sort: "desc",
  });
  const itemsPerPage = 10;

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleOpenModal = (item) => {
    // setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    // setSelectedItem(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleMenuClick = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const handleMenuClose = () => {
    setActiveMenu(null);
  }

  const handleDetailsClick = (item) => {
    navigate("/prompt-management/properties/general-properties-details", {
      state: { id: item.id },
    });
  };

  const fetchDataGeneralProps = useCallback(debounce(async (params) => {
    setIsLoading(true);
    try {
      const response = await axiosCMS.get("prompt_properties/get-general/", { params: params });
      const { data: { data: { result, total_page }, code } } = response;
      if (response.status === 200) {
        setDataGeneralProps(result || []);
        setTotalPages(total_page || 1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  }, 500), []);

  const getDataGeneralProp = useCallback((params) => {
    // API call to get function details
    return axios({
      method: "post",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `prompt_properties/get/detail/`,
      params: params,
    });
  }, []);

  const handleEditClick = async (item) => {
    try {
      const response = await getDataGeneralProp({ id: item.id });
      const {
        data: {
          code,
          data: { properties: dataProp, function: dataFunction },
          detail,
        },
      } = response;

      if (code !== 200) {
        showAlert({
          type: "error",
          title: code || "Error",
          message: detail || "Error fetching data",
        });
        return;
      }

      setDataGeneralProp({ ...dataProp, relatedFunctions: dataFunction });
      handleOpenModal("Edit");
    } catch (error) {
      const { message, response: { data: { detail } = {} } = {} } = error;

      showAlert({
        type: "error",
        title: message || "Error",
        message: detail || "Error fetching data",
      });
    }
  };

  useEffect(() => {
    fetchDataGeneralProps({
      is_export: false,
      ...selectedSort,
    });
  }, [selectedSort, fetchDataGeneralProps]);

  useEffect(() => {
    if (dataGeneralProps.length > 0) {
      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      setPaginatedDataGeneralProps(dataGeneralProps.slice(start, end));
    }
  }, [currentPage, itemsPerPage, dataGeneralProps]);

  return (
    <>
      <Alert ref={alertRef} />
      <div className="functions-table">
        <table>
          <thead>
            <tr>
              <th>
                <div className="group-flex-8 align-center">
                  <span>Properties Name</span>
                  <img
                    src={
                      selectedSort.order_by === "name"
                        ? selectedSort.sort === "desc"
                          ? sortIconAsc
                          : sortIconDesc
                        : sortIcon
                    }
                    className="sort-icon"
                    alt="Sort icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedSort({
                        order_by: "name",
                        sort:
                          selectedSort.order_by === "name"
                            ? selectedSort.sort === "asc"
                              ? "desc"
                              : "asc"
                            : "asc",
                      });
                    }}
                  />
                </div>
              </th>
              <th>Properties Type</th>
              <th>Properties Description</th>
              <th>Mandatory Properties</th>
              <th>
                <div className="group-flex-8 align-center">
                  <span>Last Update</span>
                  <img
                    src={
                      selectedSort.order_by === "updated_at"
                        ? selectedSort.sort === "desc"
                          ? sortIconAsc
                          : sortIconDesc
                        : sortIcon
                    }
                    className="sort-icon"
                    alt="Sort icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedSort({
                        order_by: "updated_at",
                        sort:
                          selectedSort.order_by === "updated_at"
                            ? selectedSort.sort === "asc"
                              ? "desc"
                              : "asc"
                            : "asc",
                      });
                    }}
                  />
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ?
              <LoadingTable columnSize={6} rowSize={4} />
              : paginatedDataGeneralProps.length === 0 ? (
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              ) : paginatedDataGeneralProps.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>
                    {item.description.length > 150
                      ? item.description.slice(0, 150) + "..."
                      : item.description}
                  </td>
                  <td>{item.isrequired ? "Yes" : "No"}</td>
                  <td>
                    {convertFormatedDate(item.updated_at)}
                  </td>
                  <td>
                    <div className="kebab-menu-container">
                      <img
                        src={kebabMenu}
                        alt="kebab menu"
                        onClick={() => handleMenuClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      {activeMenu === index && (
                        <OutsideHandler handleClose={handleMenuClose}>
                          <div className="functions-menu">
                            <div
                              className="functions-menu-option"
                              onClick={() => handleDetailsClick(item)}
                            >
                              <DetailsIcon className="functions-menu-icon" />
                              View Details
                            </div>
                            <div
                              className="functions-menu-option"
                              onClick={() => handleEditClick(item)}
                            >
                              <EditIcon className="functions-menu-icon" />
                              Edit
                            </div>
                          </div>
                        </OutsideHandler>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <ManualPagination
          itemsLength={dataGeneralProps.length}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
        />
        <EditGeneralProp
          isOpen={isEditModalOpen}
          onClose={handleCloseModal}
          onSuccess={() => {
            handleCloseModal("Edit");
            fetchDataGeneralProps(currentPage, itemsPerPage, {
              size: itemsPerPage,
              is_export: false,
              ...selectedSort,
            });
          }}
          formData={dataGeneralProp}
        />
      </div>
    </>
  );
};

export default GeneralProperties;
