import { useDispatch } from "react-redux";
import closeIcon from "../../assets/cancelIcon.svg";
import { addToast } from "../../libs/redux/slices/toastSlice";

const DeleteUserModal = ({ isOpen, user, onDeleteModal }) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    console.log("User deleted successfully");
    onDeleteModal(null);
    dispatch(addToast({
      type: 'success',
      title: 'Data has been deleted',
    }))
  }

  if (user === null) return null;
  return (
    <div className="user-add__overlay">
      <div className="user-add__modal">
        <div className="user-add__header">
          <h2 className="user-add__title">Delete User</h2>
          <button className="user-add__close" onClick={() => onDeleteModal(null)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="user-add__separator-line"></div>
        <div className="user-add__content">
          <h3 className="user-add__confirmation-title">Do you really wish to delete selected user?</h3>
          <p className="user-add__confirmation-desc">Once you delete, the selected user will be removed to the system.</p>
          <div className="user-add__footer">
            <button className="btn-base font-base" onClick={() => onDeleteModal(false)}>Cancel</button>
            <button className="btn-primary font-primary" onClick={handleSubmit}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteUserModal;