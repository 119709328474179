import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosCMS from '../../axios/axiosCMS';

export const me = createAsyncThunk('auth/me', async (_, thunkAPI) => {
  try {
    const URL = 'users/get-role';
    // const URL = 'http://localhost:8000/auth/check-auth/';
    const response = await axiosCMS.get(URL, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      },
      withCredentials: true,
    });
    if(response.status === 200){
      return thunkAPI.fulfillWithValue(response.data.data)
    }else{
      new Error(response.statusText)
    }
  } catch (error) {
    console.error('failed to fetch', error)
    return thunkAPI.rejectWithValue("Invalid Credentials")
  }
})

const initialState = {
  user: {
    fullname : 'Eva Admin',
    initials : 'EA',
    role : 'System Administrator'
  },
  isUserLoggedIn: null,
  isLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    logout: (state) => {
      state.user = {};
      state.isUserLoggedIn = null;
      state.isLoading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(me.pending, (state) => {
      state.isLoading = true;
      console.log('pending')
    });
    builder.addCase(me.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isUserLoggedIn = true;
      state.isLoading = false;
      console.log('fullfiled')
    });
    builder.addCase(me.rejected, (state) => {
      state.isUserLoggedIn = null;
      state.isLoading = false;
      console.log('rejected')
    });
  }
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
