import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import cancelIcon from "../../assets/cancelIcon.svg";
import OutsideHandler from "../../components/OutsideHandler";

const FunctionsFilter = ({ isOpen, onClose, onApply, oldFilters }) => {
  const [filters, setFilters] = useState(oldFilters);

  const [functionOptions, setFunctionOptions] = useState([]);

  const getFunctionOptions = useCallback(() => {
    axios({
      method: "get",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `prompt_function/get-function-name`,
    })
      .then((response) => {
        const { data } = response;
        if (Array.isArray(data)) {
          const options = data.map((option) => ({
            value: option.function_name,
            label: option.function_name,
          }));
          setFunctionOptions(options);
        }
      })
      .catch((error) => {
        console.error("Error fetching capabilities:", error);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      getFunctionOptions();
    }
  }, [isOpen, getFunctionOptions]);

  const handleInputChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      function_name: selectedOptions,
    }));
  };

  const handleClear = () => {
    const clearedFilters = {
      function_name: [],
    };
    setFilters(clearedFilters);
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const appliedFilters = {
      ...filters,
      function_name: filters.function_name,
    };
    onApply(appliedFilters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <OutsideHandler handleClose={() => onClose()}>
      <div className="functions-filter__wrapper">
        <div className="functions-filter__header">
          <h2 className="functions-filter__title">Filter</h2>
          <button className="functions-filter__close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="functions-filter__separator" />
        <div className="functions-filter__content">
          <div className="functions-filter__form-group">
            <label htmlFor="filter-name">Function Name</label>
            <Select
              id="filter-name"
              name="function_name"
              isMulti
              options={functionOptions}
              value={filters.function_name}
              onChange={handleInputChange}
              placeholder="-- Select Function Name --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="functions-filter__footer">
          <button
            type="button"
            className="btn-tertiary-sm font-base cursor-pointer"
            onClick={handleClear}
          >
            Clear
          </button>
          <button type="button"
            onClick={handleSubmit}
            className="btn-primary-sm font-primary cursor-pointer"
          >
            Apply
          </button>
        </div>
      </div>
    </OutsideHandler>
  );
};

export default FunctionsFilter;
