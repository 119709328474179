import React, { useState, useEffect, useCallback } from "react";
import Pagination from "./Pagination";
import StatusBox from "./StatusBox";
import loadingIcon from "../assets/loadingIcon.svg";
import LoadingComponent from "../components/Loading";
import axios from "axios";
import debounce from "../utils/Debounce";
import axiosCMS from "../libs/axios/axiosCMS";
import LoadingTable from "../components/LoadingTable";

const Analytics = ({ filters, currentPage, setCurrentPage }) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [topMetric, setTopMetric] = useState('');
  const [topPSM, setTopPSM] = useState('');
  const [topMonth, setTopMonth] = useState('');
  const [topYear, setTopYear] = useState('');
  const [topPeriod, setTopPeriod] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const fetchData = useCallback(debounce(async (currentPage, itemsPerPage, filters) => {
    setIsLoading(true);
    let newFilters = {};
    Object.keys(filters).forEach(key => {
      if (filters[key] && filters[key].value) {
        newFilters[key] = filters[key].value;
      }
    });
    try {
      const dataResponse = await axiosCMS.get(`fallback/get-analytic-gpt`, {
        params: {
          ...newFilters,
          page: currentPage,
          size: itemsPerPage,
          is_export: false
        }
      })
      const topMetricResponse = await axiosCMS.get('fallback/get-analytic-most-metrics');
      const topPSMResponse = await axiosCMS.get('fallback/get-analytic-most-psm');
      const topMonthResponse = await axiosCMS.get('fallback/get-analytic-most-month');
      const topYearResponse = await axiosCMS.get('fallback/get-analytic-most-year');
      const topPeriodResponse = await axiosCMS.get('fallback/get-analytic-most-period');

      // if (!dataResponse.ok || !topMetricResponse.ok || !topPSMResponse.ok || !topMonthResponse.ok || !topYearResponse.ok) {
      //   throw new Error('Network response was not ok');
      // }

      const dataResult = await dataResponse.data;
      const topMetricResult = await topMetricResponse.data;
      const topPSMResult = await topPSMResponse.data;
      const topMonthResult = await topMonthResponse.data;
      const topYearResult = await topYearResponse.data;
      const topPeriodResult = await topPeriodResponse.data;

      setData(dataResult.data.results || []);
      setTotalPages(dataResult.data.total_pages || 1);
      setTopMetric(topMetricResult.data || 'N/A');
      setTopPSM(topPSMResult.data || 'N/A');
      setTopMonth(topMonthResult.data || 'N/A');
      setTopYear(topYearResult.data || 'N/A');
      setTopPeriod(topPeriodResult.data || 'N/A');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  }, 500), []);


  useEffect(() => {
    fetchData(currentPage, itemsPerPage, filters);
  }, [currentPage, itemsPerPage, filters]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="analytics-container">
      <div className="analytics-metrics">
        <div className="metrics-box">
          <p className="box-title">Top Metric Queried</p>
          <p className="box-value">{topMetric.key}</p>
          <p className="box-date">{topMetric.doc_count}/{topMetric.total}</p>
        </div>
        <div className="metrics-box">
          <p className="box-title">Top PSM Queried</p>
          <p className="box-value">{topPSM.key}</p>
          <p className="box-date">{topPSM.doc_count}/{topPSM.total}</p>
        </div>
        <div className="metrics-box">
          <p className="box-title">Top Month Queried</p>
          <p className="box-value">{topMonth.key}</p>
          <p className="box-date">{topMonth.doc_count}/{topMonth.total}</p>
        </div>
        <div className="metrics-box">
          <p className="box-title">Top Year Queried</p>
          <p className="box-value">{topYear.key}</p>
          <p className="box-date">{topYear.doc_count}/{topYear.total}</p>
        </div>
        <div className="metrics-box">
          <p className="box-title">Top Period Type Queried</p>
          <p className="box-value">{topPeriod.key}</p>
          <p className="box-date">{topPeriod.doc_count}/{topPeriod.total}</p>
        </div>
      </div>
      <div className="analytics">
        <table>
          <thead>
            <tr>
              <th>Total Questions</th>
              <th>Status Code</th>
              <th>Detected Capability</th>
              <th>Location</th>
              <th>Month</th>
              <th>Year</th>
              <th className="min-width">Metrics</th>
              <th>Average Duration</th>
              <th>Average Token Consumption</th>
              <th>Average Token Cost Consumption</th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? <LoadingTable columnSize={10} rowSize={4} />
              : data.length === 0
                ? <tr>
                  <td colSpan={10} className="text-center">No data available</td>
                </tr>
                : data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.total_question}</td>
                    <td>
                      {item.status_code.map((status, index) => (
                        <StatusBox key={index} status={status} />
                      ))}
                    </td>
                    <td className="wrap">{item.detected_capability}</td>
                    <td>
                      {Array.isArray(item.location) && item.location.length > 3
                        ? `${item.location.slice(0, 3).join(', ')}, ...`
                        : Array.isArray(item.location)
                          ? item.location.join(', ')
                          : item.location}
                    </td>
                    <td>
                      {Array.isArray(item.month) && item.month.length > 3
                        ? `${item.month.slice(0, 3).join(', ')}, ...`
                        : Array.isArray(item.month)
                          ? item.month.join(', ')
                          : item.month}
                    </td>
                    <td>
                      {Array.isArray(item.year) && item.year.length > 3
                        ? `${item.year.slice(0, 3).join(', ')}, ...`
                        : Array.isArray(item.year)
                          ? item.year.join(', ')
                          : item.year}
                    </td>
                    <td className="wrap" style={{ minWidth: "200px" }}>
                      {Array.isArray(item.metrics) && item.metrics.length > 3
                        ? `${item.metrics.slice(0, 3).join(', ')}, ...`
                        : Array.isArray(item.metrics)
                          ? item.metrics.join(', ')
                          : item.metrics}
                    </td>
                    <td>{item.average_duration}s</td>
                    <td>{item.average_token_consumption}</td>
                    <td>{item.average_token_cost_consumption}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Analytics;
