import { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputErrorMessage from "../../components/InputErrorMessage";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import axiosCMS from "../../libs/axios/axiosCMS";
import { FixedSizeList } from "react-window";

const EditDashboardModal = ({ isOpen, onEditModal, dashboardId, allAccessList, psmList, regionList, estateList, complexList, divisionList, getDashboards }) => {
  const dispatch = useDispatch();
  const { register, control, setValue, getValues, reset, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all",
  });

  const { fields: fieldsAllAccess, append: appendAllAccess, remove: removeAllAccess, replace: replaceAllAccess } = useFieldArray({ control, name: "permissionAllAccess" });
  const { fields: fieldsPsm, append: appendPsm, remove: removePsm, replace: replacePsm } = useFieldArray({ control, name: "permissionPSM" });
  const { fields: fieldsRegion, append: appendRegion, remove: removeRegion, replace: replaceRegion } = useFieldArray({ control, name: "permissionRegion" });
  const { fields: fieldsEstate, append: appendEstate, remove: removeEstate, replace: replaceEstate } = useFieldArray({ control, name: "permissionEstate" });
  const { fields: fieldsDivision, append: appendDivision, remove: removeDivision, replace: replaceDivision } = useFieldArray({ control, name: "permissionDivision" });
  const { fields: fieldsComplex, append: appendComplex, remove: removeComplex, replace: replaceComplex } = useFieldArray({ control, name: "permissionComplex" });

  const [Permission, setPermission] = useState([]);

  const [permissionTabActive, setPermissionTabActive] = useState('All Access');
  const [permissionTabList, setPermissionTabList] = useState(['All Access', 'PSM', 'Region', 'Estate', 'Division', 'Complex']);

  const allAccessListToRemoveRef = useRef([]);
  const psmListToRemoveRef = useRef([]);
  const regionListToRemoveRef = useRef([]);
  const estateListToRemoveRef = useRef([]);
  const divisionListToRemoveRef = useRef([]);
  const complexListToRemoveRef = useRef([]);

  useEffect(() => {
    if (psmListToRemoveRef.current.length !== 0) removePsm(psmListToRemoveRef.current);
  }, [psmListToRemoveRef.current])

  useEffect(() => {
    if (regionListToRemoveRef.current.length !== 0) removeRegion(regionListToRemoveRef.current);
  }, [regionListToRemoveRef.current])

  useEffect(() => {
    if (estateListToRemoveRef.current.length !== 0) removeEstate(estateListToRemoveRef.current);
  }, [estateListToRemoveRef.current])
  useEffect(() => {
    if (divisionListToRemoveRef.current.length !== 0) removeDivision(divisionListToRemoveRef.current);
  }, [divisionListToRemoveRef.current])

  useEffect(() => {
    if (complexListToRemoveRef.current.length !== 0) removeComplex(complexListToRemoveRef.current);
  }, [complexListToRemoveRef.current])

  const panelAccessOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const filterAccessOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const isWaroomOptions = [
    { value: true, label: 'Warroom' },
    { value: false, label: 'Desktop' }
  ];

  const statusOptions = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
  ];

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = async (form) => {
    const formatedData = {
      id: dashboardId.id,
      name: form.dashboard_name,
      description: form.description,
      report_id: form.report_id,
      dataset_id: form.dataset_id,
      is_panel: form.panel_access.value,
      is_filter: form.filter_access.value,
      is_warroom: false,
    }

    const formatedAccessData = [
      ...form.permissionAllAccess.filter((item) => item.id || item.acc_name).map((item) => ({ acc_dashboard_id: item.id?.toString() ?? 'new', acc_master_id: item.acc_id, acc_name: item.acc_name })),
      ...form.permissionPSM.filter((item) => item.id || item.acc_name).map((item) => ({ acc_dashboard_id: item.id?.toString() ?? 'new', acc_master_id: item.acc_id, acc_name: item.acc_name })),
      ...form.permissionRegion.filter((item) => item.id || item.acc_name).map((item) => ({ acc_dashboard_id: item.id?.toString() ?? 'new', acc_master_id: item.acc_id, acc_name: item.acc_name })),
      ...form.permissionEstate.filter((item) => item.id || item.acc_name).map((item) => ({ acc_dashboard_id: item.id?.toString() ?? 'new', acc_master_id: item.acc_id, acc_name: item.acc_name })),
      ...form.permissionDivision.filter((item) => item.id || item.acc_name).map((item) => ({ acc_dashboard_id: item.id?.toString() ?? 'new', acc_master_id: item.acc_id, acc_name: item.acc_name })),
      ...form.permissionComplex.filter((item) => item.id || item.acc_name).map((item) => ({ acc_dashboard_id: item.id?.toString() ?? 'new', acc_master_id: item.acc_id, acc_name: item.acc_name }))
    ]

    const URL = 'dashboard/update/';
    try {
      const responseDashboard = await axiosCMS.post(URL, formatedData);
      const responseAccessData = await axiosCMS.post('dashboard/update-access/', {
        data: formatedAccessData,
        dashboard_id: dashboardId.id
      })
      Promise.all([responseDashboard, responseAccessData])
        .then(() => {
          dispatch(addToast({
            type: "success",
            title: "Changes were successfully saved"
          }))
          onEditModal(null);
          setIsConfirmationOpen(false);
          getDashboards();
        }).catch((error) => {
          console.error('failed to update dashboard', error);
          dispatch(addToast({
            type: "error",
            title: "Failed to save changes"
          }))
        })
    } catch (error) {
      console.error('failed to update dashboard', error);
    }
  }

  const setDefaultValue = (result) => {
    setValue('dashboard_name', result.data.dashboard_data.name);
    setValue('description', result.data.dashboard_data.description);
    setValue('report_id', result.data.dashboard_data.report_id);
    setValue('dataset_id', result.data.dashboard_data.dataset_id);
    setValue('panel_access', { value: result.data.dashboard_data.is_panel, label: result.data.dashboard_data.is_panel ? 'Yes' : 'No' });
    setValue('filter_access', { value: result.data.dashboard_data.is_filter, label: result.data.dashboard_data.is_filter ? 'Yes' : 'No' });
    setValue('is_warroom', { value: result.data.dashboard_data.is_warroom, label: result.data.dashboard_data.is_warroom ? 'Warroom' : 'Desktop' });
  }

  const removeAndReplaceFields = (data, dataCompare, appendData) => {
    const dataToRemove = [];
    const dataToAppend = [];
    data.forEach((obj) => {
      const comparedData = dataCompare.findIndex((obj2) => obj2.acc_id === obj.acc_id_detail_master)
      if (comparedData !== -1) {
        dataToRemove.push(comparedData);
        dataToAppend.push({ id: obj.dashboard_acc_id, acc_id: obj.acc_id_detail_master, acc_label: dataCompare[comparedData].acc_label, acc_name: obj.access_name });
      }
    });
    appendData(dataToAppend);
    return { dataToRemove };
  }

  const replaceDefaultList = (result) => {
    const data = result.data.access_data;

    replaceAllAccess(allAccessList);
    // if (data.all_access.length !== 0) {
    //   const { dataToRemove } = removeAndReplaceFields(data.all_access, allAccessList, appendAllAccess);
    //   allAccessListToRemoveRef.current = dataToRemove;
    // }

    replacePsm(psmList);
    if (data.psm.length !== 0) {
      const { dataToRemove } = removeAndReplaceFields(data.psm, psmList, appendPsm);
      psmListToRemoveRef.current = dataToRemove;
    }

    replaceRegion(regionList);
    if (data.region.length !== 0) {
      const { dataToRemove } = removeAndReplaceFields(data.region, regionList, appendRegion);
      regionListToRemoveRef.current = dataToRemove;
    }
    replaceEstate(estateList);
    if (data.estate.length !== 0) {
      const { dataToRemove } = removeAndReplaceFields(data.estate, estateList, appendEstate);
      estateListToRemoveRef.current = dataToRemove;
    }
    replaceDivision(divisionList);
    if (data.division.length !== 0) {
      const { dataToRemove } = removeAndReplaceFields(data.division, divisionList, appendDivision);
      divisionListToRemoveRef.current = dataToRemove;
    }
    replaceComplex(complexList);
    if (data.complex.length !== 0) {
      const { dataToRemove } = removeAndReplaceFields(data.complex, complexList, appendComplex);
      complexListToRemoveRef.current = dataToRemove;
    }
  }

  const getDashboardById = async (id) => {
    console.log('isopen');
    const URL = "dashboard/get-detail/";
    try {
      const response = await axiosCMS.get(URL + id);
      const result = response.data;
      if (response.status === 200) {
        console.log(result.data.dashboard_data);
        setDefaultValue(result);
        replaceDefaultList(result);
      }
    } catch (error) {
      console.log('Failed to get dashboard data', error);
    }
  }

  useEffect(() => {
    if (dashboardId !== null) {
      getDashboardById(dashboardId.id);
    }
  }, [dashboardId]);

  if (dashboardId === null) return null;
  return (
    <div className="dashboard-add__overlay">
      <div className="dashboard-add__modal">
        <div className="dashboard-add__header">
          <h2 className="dashboard-add__title">Edit Dashboard</h2>
          <button className="dashboard-add__close" onClick={() => onEditModal(null)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="dashboard-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleSubmit(handleFinalSubmit)}>
                <div className="dashboard-add__content">
                  <h3 className="dashboard-add__confirmation-title">Do you really wish to submit edited dashboard?</h3>
                  <p className="dashboard-add__confirmation-desc">Once you submit, the edited dashboard will be updated to the system.</p>
                  <div className="dashboard-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button type="submit" className="btn-primary font-primary">Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="dashboard-add__content">
                  <div className="dashboard-add__form-group">
                    <label className="dashboard-add__input-label" htmlFor="dashboard_name">Dashboard Name <span className="dashboard-add__input-required">*</span></label>
                    <Controller
                      control={control}
                      name="dashboard_name"
                      rules={{ required: "Dashboard Name must be filled" }}
                      render={({ field }) => (
                        <input type="text" className="dashboard-add__input-box" {...field} />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dashboard_name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="dashboard-add__form-group">
                    <label className="dashboard-add__input-label" htmlFor="description">Description</label>
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => (
                        <input type="text" className="dashboard-add__input-box" {...field} />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="dashboard-add__form-row">
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="report_id">Report ID <span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="report_id"
                        rules={{ required: "Report ID must be filled" }}
                        render={({ field }) => (
                          <input type="text" className="dashboard-add__input-box" {...field} />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="report_id"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="dataset_id">Dataset ID <span className="dashboard-add__input-required">*</span></label>
                      <input type="text" className="dashboard-add__input-box"
                        {...register("dataset_id", {
                          required: "Dataset ID must be filled",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="dataset_id"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="dashboard-add__form-row">
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="panel_access">Report Section <span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="panel_access"
                        rules={{ required: "Panel Access must be filled" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={panelAccessOptions}
                            placeholder="-- Select Panel Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="panel_access"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="filter_access">Filter Panel <span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="filter_access"
                        rules={{ required: "Filter Access must be filled" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={filterAccessOptions}
                            placeholder="-- Select Filter Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="filter_access"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="dashboard-add__form-row">
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="is_warroom">Platform <span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="is_warroom"
                        rules={{ required: "Panel Access must be filled" }}
                        defaultValue={isWaroomOptions[1]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={isWaroomOptions}
                            placeholder="-- Select Panel Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="is_warroom"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                    <div className="dashboard-add__form-group">
                      <label className="dashboard-add__input-label" htmlFor="is_active">Status<span className="dashboard-add__input-required">*</span></label>
                      <Controller
                        control={control}
                        name="is_active"
                        rules={{ required: "Filter Access must be filled" }}
                        defaultValue={statusOptions[0]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            placeholder="-- Select Filter Access --"
                            className="multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="is_active"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <InputErrorMessage key={type} message={message} />
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="dashboard-add__separator-line"></div>
                  <div className="dashboard-add__form-group">
                    <label className="dashboard-add__input-label">Permission</label>
                    <div className="dashboard-add__permission">
                      <div className="dashboard-add__permission-sidebar">
                        <ul className="dashboard-add__permission-menus">
                          {permissionTabList.map((tab, index) => (
                            <li key={index} className={`dashboard-add__permission-menu ${tab === permissionTabActive ? 'active' : ''}`} onClick={() => setPermissionTabActive(tab)}>
                              {tab}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="dashboard-add__permission-form">
                        <div className="dashboard-add__permission-wrapper">
                          <div className="dashboard-add__permission-header">
                            <div className="dashboard-add__permission-title">
                              Data Access
                            </div>
                            <div className="dashboard-add__permission-title">
                              RLS
                            </div>
                          </div>
                          {permissionTabActive === 'All Access' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsAllAccess.length}
                              itemData={fieldsAllAccess}
                              itemKey={(i) => fieldsAllAccess[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionAllAccess'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionAllAccess.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'PSM' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsPsm.length}
                              itemData={fieldsPsm}
                              itemKey={(i) => fieldsPsm[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionPSM'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionPSM.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Region' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsRegion.length}
                              itemData={fieldsRegion}
                              itemKey={(i) => fieldsRegion[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionRegion'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionRegion.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Estate' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsEstate.length}
                              itemData={fieldsEstate}
                              itemKey={(i) => fieldsEstate[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionEstate'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionEstate.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Division' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsDivision.length}
                              itemData={fieldsDivision}
                              itemKey={(i) => fieldsDivision[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionDivision'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionDivision.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                          {permissionTabActive === 'Complex' && (
                            <FixedSizeList
                              height={500}
                              itemSize={50}
                              itemCount={fieldsComplex.length}
                              itemData={fieldsComplex}
                              itemKey={(i) => fieldsComplex[i].id}
                            >
                              {({ style, index, data }) => {
                                const defaultValue = getValues()['permissionComplex'][index].acc_name || '';
                                return (
                                  <div key={index} style={style} className="dashboard-add__permission-item">
                                    <div className="dashboard-add__permission-box">
                                      <div className="dashboard-add__permission-name">
                                        {data[index].acc_label}
                                      </div>
                                      <div>
                                        <Controller
                                          render={({ field }) => <input {...field} type="text" className="dashboard-add__permission-input" />}
                                          name={`permissionComplex.${index}.acc_name`}
                                          control={control}
                                          defaultValue={defaultValue}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}
                            </FixedSizeList>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-add__footer">
                      <button type="button" className="btn-base font-base" onClick={() => { onEditModal(null) }}>
                        Cancel
                      </button>
                      <button type="submit" className="btn-primary font-primary" >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default EditDashboardModal;