import React, { useState, useEffect, useRef } from "react";
import filterIcon from "../assets/baseFilterIcon20.svg";
import exportIcon from "../assets/whiteExportIcon20.svg";
import Analytics from "./Analytics";
import Overview from "./Overview";
import FilterModal from "./FilterModal";
import AnalyticsFilterModal from "./AnalyticsFilterModal";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import "./FallbackManagement.css";
import CustomDatePicker from "../components/CustomDatePicker";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import useFallbackManagement from "../hooks/useFallbackManagement";
import axios from "axios";
import axiosCMS from "../libs/axios/axiosCMS";

const FallbackManagement = () => {
  useDocumentTitle("Fallback Management");

  const {
    activeTab,
    setActiveTab,
    currentOverviewPage,
    setCurrentOverviewPage,
    selectedOverviewFilters,
    setSelectedOverviewFilters,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    currentAnalyticsPage,
    setCurrentAnalyticsPage,
    selectedAnalyticsFilters,
    setSelectedAnalyticsFilters
  } = useFallbackManagement();

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAnalyticsFilterModalOpen, setIsAnalyticsFilterModalOpen] = useState(false);

  const dateRef = useRef(<DatePicker />);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseModal = () => {
    if (activeTab === 'Overview') {
      setIsFilterModalOpen(false);
    } else if (activeTab === 'Analytics') {
      setIsAnalyticsFilterModalOpen(false);
    }
  };

  const handleFilterButtonClick = () => {
    if (activeTab === 'Overview') {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else if (activeTab === 'Analytics') {
      setIsAnalyticsFilterModalOpen(!isAnalyticsFilterModalOpen);
    }
  };

  const handleDatePickerOpen = () => {
    dateRef.current.setOpen(true);
  }

  const handleDatePickerClose = () => {
    dateRef.current.setOpen(false);
  }

  const handleDateApply = (startDate, endDate) => {
    //Hit API here;
    setStartDate(startDate);
    setEndDate(endDate);
    setCurrentOverviewPage(1);
    handleDatePickerClose();
  };

  const handleDatePickerClear = () => {
    setStartDate(null);
    setEndDate(null);
    setCurrentOverviewPage(1);
  };

  const handleOverviewFiltersApply = (filters) => {
    setSelectedOverviewFilters(filters);
    setCurrentOverviewPage(1);
    handleCloseModal();
  };

  const handleAnalyticsFilterApply = (filters) => {
    setSelectedAnalyticsFilters(filters);
    setCurrentAnalyticsPage(1);
    handleCloseModal();
  };

  const handleExportData = async () => {
    try {
      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
      const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;
      const currentDay = format(new Date(), "yyyy-MM-dd");
      let URL = '';
      let params = {};
      if (activeTab === 'Overview') {
        URL = "fallback/get-data-gpt";
        params = {
          capability: selectedOverviewFilters.detectedCapability ? selectedOverviewFilters.detectedCapability.value : null,
          suggested_capability: selectedOverviewFilters.selectedCapability ? selectedOverviewFilters.selectedCapability.value : null,
          start_time: formattedStartDate,
          end_time: formattedEndDate,
          page: 1,
          size: 100,
          is_export: true
        };
      } else {
        let newFilters = {};
        Object.keys(selectedAnalyticsFilters).forEach(key => {
          if (selectedAnalyticsFilters[key] && selectedAnalyticsFilters[key].value) {
            newFilters[key] = selectedAnalyticsFilters[key].value;
          }
        });
        URL = "fallback/get-analytic-gpt";
        params = {
          ...newFilters,
          page: 1,
          size: 100,
          is_export: true
        };
      }
      const response = await axiosCMS.get(URL, { params, responseType: 'blob' });
      if (response.status === 200) {
        const contentDisposition = response.headers.get('content-disposition');
        let filename = `fallback_management ${currentDay}.xlsx`;

        if (contentDisposition && contentDisposition.includes('attachment')) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch && filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }

        const blob = response.data;

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <Overview
          startDate={startDate}
          endDate={endDate}
          currentPage={currentOverviewPage}
          setCurrentPage={setCurrentOverviewPage}
          filters={selectedOverviewFilters}
        />;
      case 'Analytics':
        return <Analytics
          filters={selectedAnalyticsFilters}
          currentPage={currentAnalyticsPage}
          setCurrentPage={setCurrentAnalyticsPage}
        />;
      default:
        return null;
    }
  };

  return (
    <div className="fallback-management">
      <h1 className="page-title">Fallback Management</h1>
      <div className="top-controls">
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${activeTab === "Overview" ? "active" : ""}`}
            onClick={() => handleTabClick("Overview")}
          >
            Overview
          </button>
          <button
            className={`toggle-button ${activeTab === "Analytics" ? "active" : ""}`}
            onClick={() => handleTabClick("Analytics")}
          >
            Analytics
          </button>
        </div>
        <div className="action-buttons">
          {activeTab === "Overview" && (
            <>
              <CustomDatePicker
                onApply={handleDateApply}
                onClear={handleDatePickerClear}
                onClose={handleDatePickerClose}
                oldStartDate={startDate}
                oldEndDate={endDate}
                dateRef={dateRef}
                showInput={true} />
              <div className="fallback-filter">
                <button className="btn-base with-icon-r font-base" onClick={handleFilterButtonClick}>
                  Filter
                  <img src={filterIcon} className="filter-icon" alt="filter icon" />
                </button>
                <FilterModal isOpen={isFilterModalOpen} onClose={handleCloseModal} onApply={handleOverviewFiltersApply} oldFilters={selectedOverviewFilters} />
              </div>
              <button className="btn-primary with-icon font-primary" onClick={handleExportData}>
                <img src={exportIcon} className="export-icon" alt="export icon" />
                Export Data
              </button>
            </>
          )}
          {activeTab === "Analytics" && (
            <div className="analytic-filter">
              <button className="btn-base with-icon-r font-base" onClick={handleFilterButtonClick}>
                Filter
                <img src={filterIcon} className="filter-icon" alt="filter icon" />
              </button>
              <AnalyticsFilterModal isOpen={isAnalyticsFilterModalOpen} onClose={handleCloseModal} onApply={handleAnalyticsFilterApply} oldFilters={selectedAnalyticsFilters} />
            </div>
          )}
        </div>
      </div>
      <div >{renderContent()}</div>
    </div>
  );
};

export default FallbackManagement;
