import React, { useState, useEffect } from 'react';
import cancelIcon from "../assets/cancelIcon.svg";
import axios from 'axios';
import Select from "react-select";
import OutsideHandler from '../components/OutsideHandler';
import axiosCMS from '../libs/axios/axiosCMS';

const FilterModal = ({ isOpen, onClose, onApply, oldFilters }) => {
  const [filters, setFilters] = useState(oldFilters);

  const [capabilities, setCapabilities] = useState([]);

  const getCapabilities = async () => {
    try {
      const URL = "fallback/get-master-capability";
      const response = await axiosCMS.get(URL);
      if (response.status === 200) {
        const result = response.data;
        const formatedResult = result.data.map((data) => ({ value: data, label: data }));
        setCapabilities(formatedResult);
      }
    } catch (error) {
      console.error('Error fetching capabilities:', error);
    }
  }

  useEffect(() => {
    getCapabilities();
  }, []);

  const handleDetectedCapabilityChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      detectedCapability: selectedOption,
    }));
  }

  const handleSelectedCapabilityChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedCapability: selectedOption,
    }));
  }

  const handleClear = () => {
    const clearedFilters = {
      detectedCapability: null,
      selectedCapability: null
    };
    setFilters(clearedFilters);
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onApply(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <OutsideHandler handleClose={onClose}>
      <div className="fallback-filter__wrapper">
        <div className="fallback-filter__header">
          <h2 className="fallback-filter__title">Filter</h2>
          <button className="fallback-filter__close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="fallback-filter__separator" />
        <div className="fallback-filter__content">
          <div className="fallback-filter__form-group">
            <label htmlFor="detectedCapability" className='fallback-filter__input-label'>Detected Capability</label>
            <Select
              id="detectedCapability"
              name="detectedCapability"
              options={capabilities}
              value={filters.detectedCapability}
              onChange={handleDetectedCapabilityChange}
              placeholder="-- Select Detected Capability --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={true}
            />
          </div>
          <div className="fallback-filter__form-group">
            <label htmlFor="selectedCapability" className='fallback-filter__input-label'>Selected Capability</label>
            <Select
              id="selectedCapability"
              name="selectedCapability"
              options={capabilities}
              value={filters.selectedCapability}
              onChange={handleSelectedCapabilityChange}
              placeholder="-- Select Selected Capability --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={true}
            />
          </div>
        </div>
        <div className="fallback-filter__footer">
          <button type="button" className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>
            Clear
          </button>
          <button type='button' className="btn-primary-sm font-primary cursor-pointer" onClick={handleSubmit}>
            Apply
          </button>
        </div>
      </div>
    </OutsideHandler>
  );
};

export default FilterModal;
