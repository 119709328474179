import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { removeToast } from "../libs/redux/slices/toastSlice";


const Toasts = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toast.toasts);

  const getTypeClass = (type) => {
    switch (type) {
      case 'success':
        return 'toast--success';
      case 'error':
        return 'toast--error';
      default:
        return '';
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (toasts.length > 0) {
        dispatch(removeToast(toasts[0].id));
      }
    }, 7000);

    return () => clearInterval(timer);
  }, [toasts])

  return (
    <div className="toast-container">
      {toasts.map((toast) => (
        <div key={toast.id} className={`toast ${getTypeClass(toast.type)}`}>
          <div className='toast-header'>
            {toast.type === 'error'
              ? <span className="toast-icon toast-icon--error"><ErrorIcon /></span>
              : <span className='toast-icon toast-icon--success'><CircleCheckIcon /></span>}
            <p className='toast-title'>{toast.title}</p>
            <button className='toast-close' onClick={() => dispatch(removeToast(toast.id))}>
              <CloseIcon />
            </button>
          </div>
          {toast.description && <p className='toast-message'>{toast.description}</p>}
        </div>
      ))
      }
    </div >
  );
};

function CircleCheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check"><circle cx="12" cy="12" r="10" /><path d="m9 12 2 2 4-4" /></svg>
  )
}

function ErrorIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-triangle-alert"><path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" /><path d="M12 9v4" /><path d="M12 17h.01" /></svg>
  )
}

function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
      class="lucide lucide-x">
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}

export default Toasts;