import { useDispatch } from "react-redux";
import closeIcon from "../../assets/cancelIcon.svg";
import { addToast } from "../../libs/redux/slices/toastSlice";
import axiosCMS from "../../libs/axios/axiosCMS";

const DeleteDashboardModal = ({ dashboard, onDeleteModal, getDashboards }) => {
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const URL = "dashboard/delete/";
    try {
      const response = await axiosCMS.post(URL + dashboard.id);
      if (response.status === 200) {
        dispatch(addToast({
          type: 'success',
          title: 'Data has been deleted',
        }))
        onDeleteModal(null);
        getDashboards();
      }
    } catch (error) {
      console.log('Failed to delete data', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to delete data'
      }))
    }
  }

  if (dashboard === null) return null;
  return (
    <div className="dashboard-add__overlay">
      <div className="dashboard-add__modal">
        <div className="dashboard-add__header">
          <h2 className="dashboard-add__title">Delete Dashboard</h2>
          <button className="dashboard-add__close" onClick={() => onDeleteModal(null)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="dashboard-add__separator-line"></div>
        <div className="dashboard-add__content">
          <h3 className="dashboard-add__confirmation-title">Do you really wish to delete selected dashboard?</h3>
          <p className="dashboard-add__confirmation-desc">Once you delete, the selected dashboard will be removed to the system.</p>
          <div className="dashboard-add__footer">
            <button className="btn-base font-base" onClick={() => onDeleteModal(null)}>Cancel</button>
            <button className="btn-primary font-primary" onClick={handleSubmit}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteDashboardModal;