import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import cancelIcon from "../../assets/cancelIcon.svg";
import OutsideHandler from "../../components/OutsideHandler";


const FunctionsFilter = ({ isOpen, onClose, onApply, oldFilters }) => {
  const [filters, setFilters] = useState(oldFilters);

  const [metricOptions, setMetricOptions] = useState([]);
  // Options for other dropdowns
  const dashboardTypeOptions = [
    { value: "MVD Investor", label: "MVD Investor" },
    { value: "MVD Production", label: "MVD Production" },
    { value: "iCON Metrics SPB", label: "iCON Metrics SPB" },
    { value: "iCON IPD Effectiveness", label: "iCON IPD Effectiveness" },
    { value: "iCON Metrics Denda", label: "iCON Metrics Denda" },
    // Add more options as necessary
  ];
  const locationGranularityOptions = [
    { value: "PSM", label: "PSM" },
    { value: "Region", label: "Region" },
    { value: "Estate", label: "Estate" },
    { value: "Mill", label: "Mill" },
    { value: "Downstream", label: "Downstream" },
    // Add more options as necessary
  ];
  const periodGranularityOptions = [
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Yearly", label: "Yearly" },
    // Add more options as necessary
  ];

  const getMetricOptions = useCallback(() => {
    axios({
      method: "get",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `prompt_metrics/get-metric-name`,
    })
      .then((response) => {
        const {
          data: { code, data },
        } = response;

        if (code !== 200) {
          console.log("Error fetching capabilities: ", response);
          return;
        }

        if (Array.isArray(data)) {
          const options = data.map((option) => ({
            value: option.metrics_name,
            label: option.metrics_name,
          }));
          setMetricOptions(options);
        }
      })
      .catch((error) => {
        console.error("Error fetching capabilities:", error);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      getMetricOptions();
    }
  }, [isOpen, getMetricOptions]);

  const handleInputChange = (key, selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: selectedOptions,
    }));
  };

  const handleClear = () => {
    setFilters({});
    onApply({});
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const appliedFilters = {
      ...filters,
      metric_name: filters.metric_name,
      dashboard_type: filters.dashboard_type,
      location_granuality: filters.location_granuality,
      period_granuality: filters.period_granuality,
    };
    onApply(appliedFilters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <OutsideHandler handleClose={() => onClose()}>
      <div className="metric-filter__wrapper">
        <div className="metric-filter__header">
          <h2 className="metric-filter__title">Filter</h2>
          <button className="metric-filter__close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="metric-filter__separator" />
        <div className="metric-filter__content">
          <div className="metric-filter__form-group">
            <label htmlFor="metric_name" className="metric-filter__input-label">Metric Name</label>
            <Select
              id="metric_name"
              name="metric_name"
              options={metricOptions}
              value={filters.metric_name}
              onChange={(selectedOptions) =>
                handleInputChange("metric_name", selectedOptions)
              }
              placeholder="-- Select Filter Name --"
              className="multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="metric-filter__form-group">
            <label htmlFor="dashboard_type" className="metric-filter__input-label">Dashboard Type</label>
            <Select
              id="dashboard_type"
              name="dashboard_type"
              options={dashboardTypeOptions}
              value={filters.dashboard_type}
              onChange={(selectedOptions) =>
                handleInputChange("dashboard_type", selectedOptions)
              }
              placeholder="-- Select Dashboard Type --"
              className="multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="metric-filter__form-row">
            <div className="metric-filter__form-group">
              <label htmlFor="location_granularity" className="metric-filter__input-label">Location Granularity</label>
              <Select
                id="location_granularity"
                name="location_granuality"
                options={locationGranularityOptions}
                value={filters.location_granuality}
                onChange={(selectedOptions) =>
                  handleInputChange("location_granuality", selectedOptions)
                }
                placeholder="-- Select Location Granularity --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="metric-filter__form-group">
              <label htmlFor="period-granularity" className="metric-filter__input-label">Period Granularity</label>
              <Select
                id="period-granularity"
                name="period_granuality"
                options={periodGranularityOptions}
                value={filters.period_granuality}
                onChange={(selectedOptions) =>
                  handleInputChange("period_granuality", selectedOptions)
                }
                placeholder="-- Select Period Granularity --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="metric-filter__footer">
          <button
            type="button"
            className="btn-tertiary-sm font-base cursor-pointer"
            onClick={handleClear}
          >
            Clear
          </button>
          <button type="button"
            className="btn-primary-sm font-primary cursor-pointer"
            onClick={handleSubmit}
          >
            Apply
          </button>
        </div>
      </div>
    </OutsideHandler>
  );
};

export default FunctionsFilter;
