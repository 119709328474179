import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Tooltip,
  BarController,
} from 'chart.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Chart, getElementsAtEvent } from 'react-chartjs-2';
import debounce from '../../utils/Debounce';
import axios from 'axios';
import Select from "react-select";
import LoadingChart from '../../components/LoadingChart';
import tooltipIcon from "../../assets/tooltipIcon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axiosCMS from '../../libs/axios/axiosCMS';

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, PointElement, Tooltip);

const TopTriFunctionChart = ({ selectedFilters, selectedRole, setSelectedRole, isFocusChart, indexChart, setIndexChart }) => {
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [listTooltip, setListTooltip] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [roleOptions, setRoleOptions] = useState([
    { value: '', label: 'All Access' },
    { value: 'PSM', label: 'PSM' },
    { value: 'region', label: 'Region' },
    { value: 'estate', label: 'Estate' },
  ]);

  const handleRoleChange = (selectedOption) => setSelectedRole(selectedOption);

  const getData = useCallback(debounce(async (filters, role) => {
    setIsLoading(true);
    try {
      const URL = 'user-engagement/top-3-function-by-access-role';
      const response = await axiosCMS.post(URL, {
        ...filters,
        filter_role: role.value
      });
      const result = await response.data;
      setChartData(result.data.map(item => item.total));
      setChartLabels(result.data.map(item => item.role));
      const listTooltip = {};
      result.data.forEach((x) => {
        listTooltip[x.role.toString()] = x.tooltip;
      });
      setListTooltip(listTooltip);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, 1500), []);

  useEffect(() => {
    getData(selectedFilters, selectedRole);
  }, [selectedFilters, selectedRole]);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        type: 'bar',
        backgroundColor: 'rgb(113, 174, 235)',
        data: chartData,
        maxBarThickness: 70
      },
    ]
  }

  const toolTipData = (tooltipItems) => {
    const listToolTip = listTooltip[tooltipItems[0].label] ?? [];
    if (listToolTip.length === 0) {
      return ['No data'];
    }
    const result = listToolTip.map((x, index) => `${index + 1}. ${x.function_name}: ${x.total}`);
    return result;
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: () => '',
          title: (x) => x[0].label,
          afterBody: toolTipData,
        }
      }
    },
  };

  const chartRef = useRef(null)

  const generateFocusListColor = (dataLength, focusIndex) => {
    const colors = [];
    for (let i = 0; i < dataLength; i++) {
      if (i === focusIndex) {
        colors.push('rgb(113, 174, 235)');
      } else {
        colors.push('rgb(218, 224, 222)');
      }
    }
    return colors;
  }

  const generateListColor = useCallback(() => {
    const colors = [];
    for (let i = 0; i < chartLabels.length; i++) {
      colors.push('rgb(113, 174, 235)');
    }
    return colors;
  }, [chartLabels.length]);

  const handleEventChart = (event) => {
    const element = getElementsAtEvent(chartRef.current, event);
    if (element.length > 0) {
      setIndexChart(element[0].index);
    } else {
      setIndexChart(null)
    }
  };

  const handleFocusChart = () => {
    const chart = chartRef.current;
    if (indexChart !== null) {
      chart.data.datasets[0].backgroundColor = generateFocusListColor(chartLabels.length, indexChart);
    } else {
      chart.data.datasets[0].backgroundColor = generateListColor();
    }
    chart.update();
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if(firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (isFocusChart) {
      handleFocusChart();
    }
  }, [indexChart])

  return (
    <div className='ue-section'>
      <div className='ue-filter-container'>
        <h3 className="section-title">
          Top 3 Function Queried by Access Role
          <img
            src={tooltipIcon}
            alt="tooltip icon"
            className="tooltip-icon anchor-top-tri-function"
          />
          <ReactTooltip
            id='tooltip'
            place="top"
            content="Top 3 Functions that are most frequently queried by users based on access role"
            anchorSelect=".anchor-top-tri-function"
            style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
          />
        </h3>
        <Select
          id="role"
          name="role"
          isSearchable={false}
          value={selectedRole}
          options={roleOptions}
          onChange={handleRoleChange}
          placeholder="-- Select Role --"
          classNamePrefix="select"
        />
      </div>
      <div style={{ position: 'relative', height: '300px' }}>
        {isLoading &&
          <LoadingChart />
        }
        <Chart type='bar' ref={chartRef} onClick={handleEventChart} data={data} options={chartOptions} />
      </div>
    </div>
  )
}

export default TopTriFunctionChart;