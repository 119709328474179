import { useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";

const EditMenuModal = ({ isOpen, menu, onEditModal }) => {
  const [privilegeName, setPrivilegeName] = useState();
  const [menuList, setMenuList] = useState();

  const [menuListOptions, setMenuListOptions] = useState([]);

  const [errorMessages, setErrorMessages] = useState({
    privilege_name: [],
    menu_list: [],
    other_errors: [],
  });

  const handleSelectChange = (selectedOptions, key) => {
    console.log(selectedOptions, key);
  };


  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  if (!isOpen) return null;
  return (
    <div className="privilege-add__overlay">
      <div className="privilege-add__modal">
        <div className="privilege-add__header">
          <h2 className="privilege-add__title">Edit Menu</h2>
          <button className="privilege-add__close" onClick={() => onEditModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="privilege-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <div className="privilege-add__content">
                <h3 className="privilege-add__confirmation-title">Do you really wish to submit edited menu?</h3>
                <p className="privilege-add__confirmation-desc">Once you submit, the edited menu will be added to the system.</p>
                <div className="privilege-add__footer">
                  <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                  <button className="btn-primary font-primary" onClick={handleSubmit}>Confirm</button>
                </div>
              </div>
            )
            : (
              <div className="privilege-add__content">
                <div className="privilege-add__form-group">
                  <label className="privilege-add__input-label" htmlFor="privilege_name">Privilege Name <span className="privilege-add__input-required">*</span></label>
                  <input type="text" id="privilege_name" name="privilege_name" className="privilege-add__input-box" value={privilegeName} onChange={(e) => setPrivilegeName(e.target.value)} />
                </div>
                <div className="privilege-add__form-group">
                  <label className="privilege-add__input-label" htmlFor="manu_list">Menu <span className="privilege-add__input-required">*</span></label>
                  <Select
                    isMulti={true}
                    id="manu_list"
                    name="manu_list"
                    value={menuList}
                    options={menuListOptions}
                    onChange={handleSelectChange}
                    placeholder="-- Select Filter Access --"
                    className="multi-select"
                    classNamePrefix="select"
                  />
                </div>

                <div className="privilege-add__footer">
                  <button className="btn-base font-base" onClick={() => onEditModal(false)}>
                    Cancel
                  </button>
                  <button className="btn-primary font-primary" onClick={() => handleConfirmation(true)}>
                    Submit
                  </button>
                </div>
              </div>
            )
        }
      </div>
    </div>
  )
}

export default EditMenuModal;