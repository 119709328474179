import React, { useCallback, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "./TokenCostConsumption.css";
import debounce from "../../utils/Debounce";
import { numberWithCommas } from "../../utils/FunctionHelpers";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axiosCMS from "../../libs/axios/axiosCMS";

const TCCDonutChart = ({ selectedFilters }) => {
  const [chartData, setChartData] = useState([]);
  const [token, setToken] = useState({
    input_token: { value: 0, percentage: 0 },
    output_token: { value: 0, percentage: 0 },
  });
  const [isLoading, setIsLoading] = useState(false);
  const getData = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    try {
      const URL = "token-consumption/input-output-token";
      const response = await axiosCMS.post(URL, filters);
      if (response.status == 200) {
        const result = response.data;
        setChartData([
          result.data.input_token.value,
          result.data.output_token.value,
        ]);
        setToken(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  }, 1500), []);

  const dataChart = {
    labels: ["Total Input Token", "Total Output Token"],
    datasets: [
      {
        data: chartData,
        backgroundColor: ["#16BFD6", "#F765A3"],
      },
    ],
  };

  useEffect(() => {
    getData(selectedFilters);
  }, [selectedFilters]);

  const plugin = {
    id: 'emptyDoughnut',
    afterDraw(chart, args, options) {
      const { datasets } = chart.data;
      const { color, width, radiusDecrease } = options;
      let hasData = false;

      for (let i = 0; i < datasets.length; i += 1) {
        const dataset = datasets[i];
        hasData |= dataset.data.length > 0;
      }

      if (!hasData) {
        const { chartArea: { left, top, right, bottom }, ctx } = chart;
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const r = Math.min(right - left, bottom - top) / 2;

        ctx.beginPath();
        ctx.lineWidth = width || 2;
        ctx.strokeStyle = '#e6e6e8';
        ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
        ctx.stroke();
      }
    }
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      emptyDoughnut: {
        color: 'rgba(255, 128, 0, 0.5)',
        width: 2,
        radiusDecrease: 20
      }
    },
  };

  const legendData = [
    {
      label: "Total Input Token",
      value: token.input_token.value,
      percentage: token.input_token.percentage,
      color: "#16BFD6",
    },
    {
      label: "Total Output Token",
      value: token.output_token.value,
      percentage: token.output_token.percentage,
      color: "#F765A3",
    },
  ];

  return (
    <div className="tcc-section">
      <h1 className="section-title">
        Input & Output Token
        <img
          src={tooltipIcon}
          alt="tooltip icon"
          className="tooltip-icon anchor-input-output-token"
        />
        <ReactTooltip
          id='tooltip'
          place="top"
          content="A comparison of the total consumption of Input Token and Output Token"
          anchorSelect=".anchor-input-output-token"
          style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
        />
      </h1>
      <div className="rq-chart-and-legend">
        <div style={{ height: '200px', width: '200px' }}>
          <Doughnut className="rq-donut" data={dataChart} options={chartOptions} plugins={[plugin]} />
        </div>
        <div className="rq-legend-container">
          {legendData.map((item, index) => (
            <div key={index} className="rq-legend-item">
              <div className="rq-legend-info">
                <div
                  className="rq-legend-color-box"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span style={{ marginRight: "10px" }}>{item.label}</span>
              </div>
              <div className="rq-legend-value">
                <span className="rq-value">{numberWithCommas(item.value)}</span>
                <div className="rq-percentage-bar">
                  <div
                    className="rq-percentage-fill"
                    style={{
                      width: item.percentage + "%",
                      backgroundColor: item.color,
                    }}
                  ></div>
                </div>
                <span className="rq-percentage-text">{item.percentage}%</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TCCDonutChart;
