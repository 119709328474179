import { useDispatch } from "react-redux";
import closeIcon from "../../assets/cancelIcon.svg";
import { addToast } from "../../libs/redux/slices/toastSlice";
import axiosCMS from "../../libs/axios/axiosCMS";
import { getRoles } from "@testing-library/react";

const DeleteRoleModal = ({ isOpen, role, onDeleteModal, getRoles }) => {
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const URL = 'role/delete/';
    try {
      const response = await axiosCMS.post(URL + role.id,);
      if (response.status === 200) {
        dispatch(addToast({
          type: 'success',
          title: 'Data has been deleted',
        }))
        onDeleteModal(null);
        getRoles();
      }
    } catch (error) {
      dispatch(addToast({
        type: 'error',
        title: 'Failed to delete data',
      }))
    }
  }

  if (role === null) return null;
  return (
    <div className="role-add__overlay">
      <div className="role-add__modal">
        <div className="role-add__header">
          <h2 className="role-add__title">Delete Role</h2>
          <button className="role-add__close" onClick={() => onDeleteModal(null)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="role-add__separator-line"></div>
        <div className="role-add__content">
          <h3 className="role-add__confirmation-title">Do you really wish to delete selected role?</h3>
          <p className="role-add__confirmation-desc">Once you delete, the selected role will be removed to the system.</p>
          <div className="role-add__footer">
            <button className="btn-base font-base" onClick={() => onDeleteModal(false)}>Cancel</button>
            <button className="btn-primary font-primary" onClick={handleSubmit}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteRoleModal;