import React, { useState, useEffect } from 'react';
import './FallbackManagement.css';
import cancelIcon from "../assets/cancelIcon.svg";
import Select from "react-select";
import OutsideHandler from '../components/OutsideHandler';
import axiosCMS from '../libs/axios/axiosCMS';

const monthOptions = [
  { label: 'ALL', value: '' },
  { label: 'Jan', value: 1 },
  { label: 'Feb', value: 2 },
  { label: 'Mar', value: 3 },
  { label: 'Apr', value: 4 },
  { label: 'May', value: 5 },
  { label: 'Jun', value: 6 },
  { label: 'Jul', value: 7 },
  { label: 'Aug', value: 8 },
  { label: 'Sep', value: 9 },
  { label: 'Oct', value: 10 },
  { label: 'Nov', value: 11 },
  { label: 'Dec', value: 12 },
];

const AnalyticsFilterModal = ({ isOpen, onClose, onApply, oldFilters }) => {
  const [filterOptions, setFilterOptions] = useState({
    detected_capability: [
      { value: "", label: "ALL" }
    ],
    year: [
      { value: "", label: "ALL" }
    ],
    metric: [
      { value: "", label: "ALL" }
    ],
    psm: [
      { value: "", label: "ALL" }
    ],
    region: [
      { value: "", label: "ALL" }
    ],
    estate: [
      { value: "", label: "ALL" }
    ],
  });

  const [filters, setFilters] = useState({
    detected_capability: oldFilters.detected_capability || { value: "", label: "ALL" },
    month: oldFilters.month || { value: "", label: "ALL" },
    year: oldFilters.year || { value: "", label: "ALL" },
    metrics: oldFilters.metrics || { value: "", label: "ALL" },
    psm: oldFilters.psm || { value: "", label: "ALL" },
    region: oldFilters.region || { value: "", label: "ALL" },
    estate: oldFilters.estate || { value: "", label: "ALL" },
  });

  const fetchFilterOptions = (filter) => {
    const URL = "fallback/get-master-analytic-filter";
    return axiosCMS.get(URL, {
      params: {
        filter: filter
      }
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.code === 200) {
          return data.data.map((item) => ({ label: item, value: item }));
        } else {
          console.error(`Error fetching ${filter} options:`, data.message);
          return [];
        }
      })
      .catch((error) => {
        console.error(`Error fetching ${filter} options:`, error);
        return [];
      });
  };

  const fetchCapabilities = () => {
    const URL = "fallback/get-master-capability/w/not-found";
    return axiosCMS.get(URL)
      .then((response) => response.data)
      .then((data) => {
        if (data.code === 200) {
          return data.data.map((item) => ({ value: item, label: item }));
        } else {
          console.error('Error fetching capabilities:', data.message);
          return [];
        }
      })
      .catch((error) => {
        console.error('Error fetching capabilities:', error);
        return [];
      });
  };

  useEffect(() => {
    if (isOpen) {
      fetchCapabilities().then((data) => {
        setFilterOptions((prevOptions) => ({
          ...prevOptions,
          detected_capability: data,
        }));
      });

      const filtersToFetch = ['year', 'metric', 'psm', 'region', 'estate'];
      filtersToFetch.forEach((filter) => {
        fetchFilterOptions(filter).then((data) => {
          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            [filter]: [{ value: "", label: "ALL" }, ...data],
          }));
        });
      });
    }
  }, [isOpen]);


  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
  };

  const handleClear = () => {
    const clearedFilters = {
      detected_capability: null,
      month: null,
      year: null,
      metrics: null,
      psm: null,
      region: null,
      estate: null,
    };
    setFilters({
      detected_capability: { value: "", label: "ALL" },
      month: { value: "", label: "ALL" },
      year: { value: "", label: "ALL" },
      metrics: { value: "", label: "ALL" },
      psm: { value: "", label: "ALL" },
      region: { value: "", label: "ALL" },
      estate: { value: "", label: "ALL" },
    });
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = () => {
    onApply(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <OutsideHandler handleClose={onClose}>
      <div className="analytic-filter__wrapper">
        <div className="analytic-filter__header">
          <h2 className="analytic-filter__title">Filter</h2>
          <button className="analytic-filter__close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="analytic-filter__separator" />
        <div className='analytic-filter__content'>
          <div className='analytic-filter__form-group'>
            <label htmlFor="detected-capability" className='analytic-filter__input-label'>Detected Capability</label>
            <Select
              id="detected-capability"
              name="detected_capability"
              options={filterOptions.detected_capability}
              value={filters.detected_capability}
              onChange={handleInputChange}
              placeholder="-- Select Detected Capability --"
              className="multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className='analytic-filter__form-group'>
            <label htmlFor="metrics" className='analytic-filter__input-label'>Metrics</label>
            <Select
              id="metrics"
              name="metrics"
              options={filterOptions.metric}
              value={filters.metrics}
              onChange={handleInputChange}
              placeholder="-- Select Metrics --"
              className="multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className='analytic-filter__form-row'>
            <div className='analytic-filter__form-group'>
              <label htmlFor="month" className='analytic-filter__input-label'>Month</label>
              <Select
                id="month"
                name="month"
                options={monthOptions}
                value={filters.month}
                onChange={handleInputChange}
                placeholder="-- Select Month --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className='analytic-filter__form-group'>
              <label htmlFor="year" className='analytic-filter__input-label'>Year</label>
              <Select
                id="year"
                name="year"
                options={filterOptions.year}
                value={filters.year}
                onChange={handleInputChange}
                placeholder="-- Select Year --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
          <div className='analytic-filter__form-row'>
            <div className='analytic-filter__form-group'>
              <label htmlFor="psm" className='analytic-filter__input-label'>PSM</label>
              <Select
                id="psm"
                name="psm"
                options={filterOptions.psm}
                value={filters.psm}
                onChange={handleInputChange}
                placeholder="-- Select PSM --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className='analytic-filter__form-group'>
              <label htmlFor="region" className='analytic-filter__input-label'>Region</label>
              <Select
                id="region"
                name="region"
                options={filterOptions.region}
                value={filters.region}
                onChange={handleInputChange}
                placeholder="-- Select Region --"
                className="multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
          <div className="analytic-filter__form-group">
            <label htmlFor="estate" className='analytic-filter__input-label'>Estate</label>
            <Select
              id="estate"
              name="estate"
              options={filterOptions.estate}
              value={filters.estate}
              onChange={handleInputChange}
              placeholder="-- Select Estate --"
              className="multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="analytic-filter__footer">
          <button type="button" className="btn-tertiary-sm font-base cursor-pointer" onClick={handleClear}>
            Clear
          </button>
          <button type="button" className="btn-primary-sm font-primary cursor-pointer" onClick={handleSubmit}>
            Apply
          </button>
        </div>
      </div>
    </OutsideHandler>
  );
};

export default AnalyticsFilterModal;
