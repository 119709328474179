import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import calendarIcon from "../../assets/baseCalendarIcon20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import TCCLineChart from "./TCCLineChart";
import TCCDonutChart from "./TCCDonutChart";
import TCCFilterModal from "./TCCFilterModal";
import TCCDateModal from "./TCCDateModal"
import "./TokenCostConsumption.css";
import TCCAnalyticTable from "./TCCAnalyticTable";
import TCCOverview from "./TCCOverview";
import TCCForecastChart from "./TCCForecastChart";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import debounce from "../../utils/Debounce";
import { useSelector } from "react-redux";
import TopBar from "../../components/TopBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const TokenCostConsumption = () => {
  useDocumentTitle("Token Cost Consumption");
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const lineChartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState("100%");
  const isSidePanelOpen = useSelector((state) => state.sidePanel.isSidePanelOpen);

  const [listLabelChooseDate, setListLabelChooseDate] = useState("");
  const [listLabelChooseDateCount, setListLabelChooseDateCount] = useState(0);

  const months = [
    { title: "January", altTitle: 'Jan', value: 1 },
    { title: "February", altTitle: 'Feb', value: 2 },
    { title: "March", altTitle: 'Mar', value: 3 },
    { title: "April", altTitle: 'Apr', value: 4 },
    { title: "May", altTitle: 'May', value: 5 },
    { title: "June", altTitle: 'Jun', value: 6 },
    { title: "July", altTitle: 'Jul', value: 7 },
    { title: "August", altTitle: 'Aug', value: 8 },
    { title: "September", altTitle: 'Sep', value: 9 },
    { title: "October", altTitle: 'Oct', value: 10 },
    { title: "November", altTitle: 'Nov', value: 11 },
    { title: "December", altTitle: 'Dec', value: 12 }
  ];

  const currentMonth = months[new Date().getMonth()];
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState([currentMonth]);
  const [selectedYear, setSelectedYear] = useState([currentYear]);

  const handleOpenModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(true);
        break;
      case "filter":
        setIsFilterModalOpen(true);
        break;
      default:
        break;
    }
  };

  const getListLabelChooseDate = (selectedMonth, selectedYear) => {
    const listLabel = []
    for (let i = 0; i < selectedYear.length; i++) {
      for (let j = 0; j < selectedMonth.length; j++) {
        listLabel.push(`${selectedMonth[j].altTitle} ${selectedYear[i]}`);
      }
    }
    setListLabelChooseDate(listLabel.slice(0, 3).join(', '));
  }

  const getListLabelChooseDateCount = (selectedMonth, selectedYear) => {
    const listLabelCount = selectedMonth.length * selectedYear.length;
    if (listLabelCount > 5) {
      setListLabelChooseDateCount(listLabelCount - 4);
    } else {
      setListLabelChooseDateCount(0);
    }
  }

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      date_months: selectedMonth.map((data) => data.value).sort((a, b) => a - b),
      date_years: selectedYear.sort((a, b) => a - b)
    }));
    getListLabelChooseDate(selectedMonth, selectedYear);
    getListLabelChooseDateCount(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear])

  const handleDateModal = (action = null) => {
    if (isDateModalOpen === null) {
      setIsDateModalOpen(!isDateModalOpen);
    } else {
      setIsDateModalOpen(action);
    }
  }

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(false);
        break;
      case "filter":
        setIsFilterModalOpen(false);
        break;
      default:
        break;
    }
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters({ ...selectedFilters, ...filters });
    handleCloseModal("filter");
  };

  const handleResize = useCallback(debounce(() => {
    if (!lineChartRef.current) return;
    const oldWidth = lineChartRef.current.offsetWidth;
    if (oldWidth > 600) {
      const newWidth = oldWidth - 500;
      setChartWidth(`${newWidth}px`);
    } else {
      setChartWidth('100%');
    }
  }, 300), [])

  useEffect(() => {
    if (lineChartRef.current) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [lineChartRef])

  useEffect(() => {
    if (lineChartRef.current) {
      handleResize();
    }
  }, [isSidePanelOpen])

  const prevRoutes = [
    { name: 'Analytics Dashboard', route: null }
  ]

  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'Token Cost Consumption'} />
      <div className="token-cost-consumption-container tcc">
        <title>Token Cost Consumption</title>
        <div className="tcc-header-section">
          <h1 className="tcc-header-title">Token/Cost Consumption</h1>
          <div className="group-flex-8">
            <div className="dropdown-calendar">
              <button className="btn-date large cursor-pointer" onClick={handleDateModal}>
                <img
                  src={calendarIcon}
                  alt="calendar icon"
                  className="date-button-icon"
                />
                {listLabelChooseDate ?? "Choose Date"} {listLabelChooseDateCount > 0 && `+${listLabelChooseDateCount}`}
              </button>
              <TCCDateModal
                isOpen={isDateModalOpen}
                onClose={handleDateModal}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                setSelectedMonth={setSelectedMonth}
                setSelectedYear={setSelectedYear}
                currentMonth={currentMonth}
                currentYear={currentYear}
              />

            </div>
            <div className="tcc-filter">
              <button
                className="btn-base with-icon font-base"
                onClick={() => handleOpenModal("filter")}
              >
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className="filter-button-icon"
                />
                Filter
              </button>
              <TCCFilterModal
                isOpen={isFilterModalOpen}
                onClose={() => handleCloseModal("filter")}
                onApply={handleFilterApply}
              />
            </div>
          </div>
        </div>



        <TCCOverview
          selectedFilters={selectedFilters}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />

        <div className="tcc-section-container" ref={lineChartRef}>
          <TCCLineChart
            chartWidth={chartWidth}
            selectedFilters={selectedFilters}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />
          <TCCDonutChart
            selectedFilters={selectedFilters}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />
        </div>

        <TCCForecastChart
          selectedFilters={selectedFilters}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />

        <TCCAnalyticTable
          selectedFilters={selectedFilters}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      </div>
    </>
  );
};

export default TokenCostConsumption;
