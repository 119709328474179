import { useState } from "react";
import closeIcon from "../../assets/cancelIcon.svg";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputErrorMessage from "../../components/InputErrorMessage";

const AddMenuModal = ({ isOpen, menu, onAddModal }) => {
  const { register, control, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const [selectedMenu, setSelectedMenu] = useState([]);
  const [menuListOptions, setMenuListOptions] = useState([]);

  const [errorMessages, setErrorMessages] = useState({
    privilege_name: [],
    menu_list: [],
    other_errors: [],
  });

  const handleSelectChange = (selectedOptions, key) => {
    console.log(selectedOptions, key);
  };


  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmation = (action = null) => {
    if (action !== null) {
      setIsConfirmationOpen(action);
    } else {
      setIsConfirmationOpen(!isConfirmationOpen);
    }
  }

  const handleFinalSubmit = (e) => {
    e.preventDefault()
  }

  if (!isOpen) return null;
  return (
    <div className="privilege-add__overlay">
      <div className="privilege-add__modal">
        <div className="privilege-add__header">
          <h2 className="privilege-add__title">Add Menu</h2>
          <button className="privilege-add__close" onClick={() => onAddModal(false)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="privilege-add__separator-line"></div>
        {
          isConfirmationOpen
            ? (
              <form onSubmit={handleFinalSubmit}>
                <div className="privilege-add__content">
                  <h3 className="privilege-add__confirmation-title">Do you really wish to submit new menu?</h3>
                  <p className="privilege-add__confirmation-desc">Once you submit, the new menu will be added to the system.</p>
                  <div className="privilege-add__footer">
                    <button className="btn-base font-base" onClick={() => handleConfirmation(false)}>Cancel</button>
                    <button className="btn-primary font-primary" onClick={handleSubmit}>Confirm</button>
                  </div>
                </div>
              </form>
            )
            : (
              <form onSubmit={handleSubmit(handleConfirmation)}>
                <div className="privilege-add__content">
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="privilege_name">Privilege Name <span className="privilege-add__input-required">*</span></label>
                    <input type="text" className="privilege-add__input-box"
                      {...register("privilege_name", {
                        required: "Privilege must be filled",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="privilege_name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>
                  <div className="privilege-add__form-group">
                    <label className="privilege-add__input-label" htmlFor="manu_list">Menu <span className="privilege-add__input-required">*</span></label>
                    <Controller
                      name="menu_list"
                      control={control}
                      rules={{ required: "Menu must be filled" }}
                      render={({ field }) => (
                        <Select
                          name="menu_list"
                          value={selectedMenu}
                          options={menuListOptions}
                          onChange={handleSelectChange}
                          placeholder="-- Select Menu--"
                          className="multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="menu_list"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <InputErrorMessage key={type} message={message} />
                        ))
                      }
                    />
                  </div>

                  <div className="privilege-add__footer">
                    <button type="button" className="btn-base font-base" onClick={() => onAddModal(false)}>
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary font-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </div>
  )
}

export default AddMenuModal;