import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import OutsideHandler from "./OutsideHandler";
import { logout } from "../libs/redux/slices/authSlice";
import { useState } from "react";
import logoutIcon from "../assets/logoutIcon16.svg";

const TopBar = ({ prevRoutes, currentRoute }) => {
  const user = useSelector(state => state.auth.user);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenProfile = () => {
    setIsProfileOpen(true);
  }

  const handleCloseProfile = () => {
    setIsProfileOpen(false);
  }

  const handleLogout = () => {
    dispatch(logout());
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
  }

  return (
    <div className="topbar-container">
      <div>
        {prevRoutes.map((route, index) => (
          <span key={index}>{
            route.route ?
              <Link to={route.route} className="breadcrumb-prev link-style">{route.name}</Link> :
              <span className="breadcrumb-prev">{route.name}</span>
          }{" > "}</span>
        ))}
        <span className="breadcrumb-current">{currentRoute}</span>
      </div>
      <div className="profile-container">
        <div>
          <div className="name-text">{user.fullname}</div>
          <div className="role-text">{user.role}</div>
        </div>
        <OutsideHandler handleClose={handleCloseProfile}>
          <div className="avatar-container" onClick={handleOpenProfile}>
            <div className="avatar-icon cursor-pointer">
              <span className="cursor-pointer initials-name">{user.initials}</span>
            </div>
            {isProfileOpen &&
              (
                <div className="profile-dropdown">
                  <div className="profile-contents">
                    <div className="profile-item--red">
                      <LogoutIcon />
                      <div onClick={handleLogout}>Logout</div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </OutsideHandler>
      </div>
    </div>
  )
}

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ed1c24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1="21" x2="9" y1="12" y2="12" /></svg>
  )
}

export default TopBar