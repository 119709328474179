import { useState } from "react";
import TopBar from "../../components/TopBar";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import OutsideHandler from "../../components/OutsideHandler";
import "./MenuManagement.css";
import Pagination from "../../components/Pagination";
import FilterModal from "./FilterModal";
import LoadingComponent from "../../components/Loading";
import EditMenuModal from "./EditMenuModal";
import DeleteMenuModal from "./DeleteMenuModal";
import AddMenuModal from "./AddMenuModal";

import searchIcon from "../../assets/searchIcon20.svg";
import clearIcon from "../../assets/xIcon20.svg";
import plusIcon from "../../assets/plusIconWhite20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";
import kebabMenuIcon from "../../assets/kebabMenu.svg";
import { ReactComponent as TrashIcon } from "../../assets/trashIcon16.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";



const MenuManagement = () => {

  const [search, setSearch] = useState('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddMenuModalOpen, setIsAddMenuModalOpen] = useState(false);
  const [isEditMenuModalOpen, setIsEditMenuModalOpen] = useState(null);
  const [isDeleteMenuModalOpen, setIsDeleteMenuModalOpen] = useState(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [privileges, setPrivileges] = useState([
    {
      id: 1,
      name: 'Admin',
      list_menu: [],
      lastUpdated: '2021-08-10',
    },
    {
      id: 2,
      name: 'User',
      list_menu: [],
      lastUpdated: '2021-08-10',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSort, setSelectedSort] = useState({ order_by: "updated_at", sort: "asc" });
  const [selectedFilters, setSelectedFilters] = useState({});

  useDocumentTitle('CMS Permission')

  const prevRoutes = [
    { name: 'User & Role Management', route: null }
  ]

  const handleFilterModal = (action = null) => {
    if (action === null) {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else {
      setIsFilterModalOpen(action);
    }
  }

  const handleAddMenuModal = (action = null) => {
    if (action === null) {
      setIsAddMenuModalOpen(!isAddMenuModalOpen);
    } else {
      setIsAddMenuModalOpen(action);
    }
  }

  const handleEditMenuModal = (index = null) => {
    setIsEditMenuModalOpen(isEditMenuModalOpen === index ? null : index);
  }

  const handleDeleteMenuModal = (index) => {
    setIsDeleteMenuModalOpen(isDeleteMenuModalOpen === index ? null : index);
  }

  const handleFilterApply = (filters) => {
    // Apply filters
    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...filters }));
  }

  const handleActiveMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  }

  const handlePageChange = (action) => {
    if (action === 'prev') {
      setCurrentPage(currentPage - 1);
    } else if (action === 'next') {
      setCurrentPage(currentPage + 1);
    }
  }


  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'Privilege Management'} />
      <div className="privilege-mg">
        <div className="privilege-mg__header-container">
          <h1 className="privilege-mg__header-title">CMS Permission</h1>
          <div className="privilege-mg__action-container">
            <div style={{ position: 'relative' }}>
              <input type="text" placeholder="Search" className="privilege-search" value={search} onChange={(e) => setSearch(e.target.value)} />
              {search
                ? <img src={clearIcon} alt="search icon" className="privilege-search__icon" onClick={() => setSearch('')} />
                : <img src={searchIcon} alt="search icon" className="privilege-search__icon" />
              }
            </div>
            <div className="privilege-filter">
              <button onClick={handleFilterModal} className="btn-base with-icon font-base">
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className="filter-button-icon"
                />
                Filter
              </button>
              <FilterModal isOpen={isFilterModalOpen} onFilterModal={handleFilterModal} onApply={handleFilterApply} />
            </div>
            <button onClick={handleAddMenuModal} className="btn-primary with-icon font-primary" >
              <img
                src={plusIcon}
                alt="filter icon"
                className="filter-button-icon"
              />
              Add Permission
            </button>
            <AddMenuModal isOpen={isAddMenuModalOpen} onAddModal={handleAddMenuModal} />
          </div>
        </div>
        <div className="privilege-table">
          <div className="privilege-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th>Permission Group</th>
                  <th>Permission</th>
                  <th>Status</th>
                  <th>
                    <div className="group-flex-8 align-center">
                      <span>Last Updated</span>
                      <img
                        src={
                          selectedSort.order_by === "updated_at"
                            ? selectedSort.sort === "asc"
                              ? sortIconAsc
                              : sortIconDesc
                            : sortIcon
                        }
                        className="sort-icon"
                        alt="Sort icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedSort({
                            order_by: "updated_at",
                            sort:
                              selectedSort.order_by === "updated_at"
                                ? selectedSort.sort === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc",
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th style={{ width: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ?
                    <tr>
                      <td colSpan={6}>
                        <LoadingComponent message={'Loading data...'} />
                      </td>
                    </tr>
                    : (
                      privileges.length > 0 ? privileges.map(privilege => (
                        <tr key={privilege.id}>
                          <td>{privilege.name}</td>
                          <td>{privilege.list_menu}</td>
                          <td>
                            {privilege.is_active
                              ? <span className="privilege-badge--active">Active</span>
                              : <span className="privilege-badge--inactive">Inactive</span>
                            }
                          </td>
                          <td>{privilege.lastUpdated}</td>
                          <td>
                            <div className="kebab-menu-container">
                              <img
                                src={kebabMenuIcon}
                                alt="kebab menu"
                                onClick={() => handleActiveMenu(privilege.id)}
                                style={{ cursor: "pointer" }}
                              />
                              {activeMenuIndex === privilege.id && (
                                <OutsideHandler handleClose={handleActiveMenu}>
                                  <div className="privilege-menu">
                                    <div
                                      className="privilege-menu-option"
                                      onClick={() => handleEditMenuModal(privilege.id)}
                                    >
                                      <EditIcon className="privilege-menu-icon" />
                                      Edit
                                    </div>
                                    <div
                                      className="privilege-menu-option"
                                      onClick={() => handleDeleteMenuModal(privilege.id)}
                                    >
                                      <TrashIcon className="privilege-menu-icon" />
                                      Delete
                                    </div>
                                  </div>
                                </OutsideHandler>
                              )}
                              <EditMenuModal isOpen={isEditMenuModalOpen === privilege.id} privilege={privilege} onEditModal={handleEditMenuModal} />
                              <DeleteMenuModal isOpen={isDeleteMenuModalOpen === privilege.id} privilege={privilege} onDeleteModal={handleDeleteMenuModal} />
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={6} className="text-center">No data available</td>
                        </tr>
                      )
                    )
                }
              </tbody>
            </table>
          </div>
          <Pagination currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} />
        </div>
      </div>
    </>
  )
}

export default MenuManagement;