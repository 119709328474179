import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import backArrow from "../assets/backArrow.svg";
import StatusBox from "./StatusBox";
import "./FallbackDetails.css";
import { convertFormatedDate } from "../utils/FunctionHelpers";
import axiosCMS from "../libs/axios/axiosCMS";

const FallbackDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [historyData, setHistoryData] = useState([]);
  const data = useMemo(() => location.state?.data.data || {}, [location.state]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const params = {
          id: data.id,
          timestamp: data.message.message.timestamp,
          user_email: data.user.user_id,
          conv_id: data.user.room_id
        };
        const URL = "fallback/get-conv-history-gpt";
        const response = await axiosCMS.get(URL, { params: params });

        const result = await response.data;
        if (response.status === 200) {
          setHistoryData(result.data);
        }
      } catch (error) {
        console.error("Error fetching conversation history:", error);
      }
    };

    fetchHistory();
  }, [data]);

  const toSeparatedString = (data) => {
    return data.join(', ');
  }

  const attributes = [
    { label: "User Email", value: data.user_detail.user_mail || "null" },
    { label: "Role", value: data.user_detail.role || "null" },
    { label: "RLS", value: data.user_detail.access ? <RLSComponent rls={data.user_detail.access} /> : "null" },
    { label: "Timestamp", value: convertFormatedDate(data.message.message.timestamp) },
    { label: "Duration", value: data.data.response.duration },
    {
      label: "Status",
      value: <StatusBox status={data.data.response.status_code} />,
    },
    { label: "Detected Capability", value: data.data.function },
    {
      label: "Selected Capability",
      value: data.data.selected_capability || "null",
    },
    { label: "Token Consumption", value: data.token_consumption },
    { label: "Token Cost Consumption", value: data.token_cost_consumption },
  ];

  const handleClick = () => {
    navigate("/fallback-management");
  };

  return (
    <div className="fallback-details">
      <div className="detail-header">
        <img src={backArrow} alt="Back" onClick={handleClick} />
        Fallback Details
      </div>
      <div className="detail-content">
        <div className="detail-overview">
          <h2 className="content-header">Overview</h2>
          <div className="box-container">
            {attributes.map((attr, index) => (
              <div key={index} className="box">
                <p className="label">{attr.label}</p>
                <p className="value">{attr.value}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="detail-issue">
          <h2 className="content-header">Issue Description</h2>
          <div className="issue-content">
            <div className="issue-box">
              <p className="label">Question</p>
              <div className="separator"></div>
              <p className="value">{data.message.question}</p>
            </div>
            <div className="issue-box">
              <p className="label">Bot Answer</p>
              <div className="separator"></div>
              <p className="value">"{data.message.message.message}"</p>
            </div>
          </div>
        </div>
        <div className="detail-history">
          <h2 className="content-header">Conversation History</h2>
          <div className="closing-rating">
            <div className="history-box">
              <p className="box-title">Closing</p>
              <p className="box-value">{data.data.is_closing}</p>
            </div>
            <div className="history-box">
              <p className="box-title">Rating</p>
              <p className="box-value">
                {data.data.feedback.rating ? data.data.feedback.rating : "null"}
              </p>
            </div>
          </div>
          <div className="history-table-container">
            <table className="history-table">
              <thead>
                <tr>
                  <th>Questions</th>
                  <th>Bot Answer</th>
                  <th>Status Code</th>
                  <th>Capability</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {historyData.length > 0 ? (
                  historyData.map((historyItem, index) => (
                    <tr key={index}>
                      <td>{historyItem.question}</td>
                      <td>{historyItem.bot_answer}</td>
                      <td>{<StatusBox status={historyItem.status_code} />}</td>
                      <td>{historyItem.detected_capability}</td>
                      <td>{convertFormatedDate(historyItem.timestamp)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No history data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const RLSComponent = ({ rls }) => {
  const [data, setData] = useState(rls);
  const [showMore, setShowMore] = useState(false);

  const toSeparatedString = (data) => {
    return data.join(', ');
  }

  const getRLS = (data) => {
    const slicedData = data.length > 8 ? data.slice(0, 7) : data;
    setData(slicedData);
  }

  const handleShowMore = () => {
    setData(rls);
    setShowMore(true);
  }

  useEffect(() => {
    getRLS(data);
  }, []);

  return (
    <div>
      {toSeparatedString(data)}
      {
        rls.length > 7 && !showMore &&
        <>
          {", "}
          <span className="show-more" onClick={handleShowMore}>...</span>
        </>
      }
    </div>
  )
}

export default FallbackDetails;
