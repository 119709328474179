import { useCallback, useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import OutsideHandler from "../../components/OutsideHandler";
import "./DashboardManagement.css";
import Pagination from "../../components/Pagination";
import FilterModal from "./FilterModal";
import LoadingComponent from "../../components/Loading";
import EditDashboardModal from "./EditDashboardModal";
import DeleteDashboardModal from "./DeleteDashboardModal";
import AddDashboardModal from "./AddDashboardModal";

import searchIcon from "../../assets/searchIcon20.svg";
import clearIcon from "../../assets/xIcon20.svg";
import plusIcon from "../../assets/plusIconWhite20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";
import kebabMenuIcon from "../../assets/kebabMenu.svg";
import { ReactComponent as TrashIcon } from "../../assets/trashIcon16.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import axiosCMS from "../../libs/axios/axiosCMS";
import { useDispatch } from "react-redux";
import { addToast } from "../../libs/redux/slices/toastSlice";
import debounce from "../../utils/Debounce";
import LoadingTable from "../../components/LoadingTable";
import { convertFormatedDate } from "../../utils/FunctionHelpers";

const PAGE_SIZE = 10;
const DEFAULT_SORT = "desc";
const DEFAULT_ORDER_BY = "updated_at";

const DashboardManagement = () => {
  useDocumentTitle('Dashboard Management')
  const prevRoutes = [
    { name: 'User & Role Management', route: null }
  ]
  const dispatch = useDispatch();
  const [allAccessList, setAllAccessList] = useState([]);
  const [psmList, setPSMList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [estateList, setEstateList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [complexList, setComplexList] = useState([]);

  const [search, setSearch] = useState('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddDashboardModalOpen, setIsAddDashboardModalOpen] = useState(false);
  const [isEditDashboardModalOpen, setIsEditDashboardModalOpen] = useState(null);
  const [isDeleteDashboardModalOpen, setIsDeleteDashboardModalOpen] = useState(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSort, setSelectedSort] = useState({ order_by: "updated_at", sort: "asc" });
  const [selectedFilters, setSelectedFilters] = useState({
    search: "",
    dashboard_name: [],
    report_section: "",
    filter_panel: "",
    page: 1,
    size: PAGE_SIZE,
    sort: DEFAULT_SORT,
    order_by: DEFAULT_ORDER_BY,
  });

  const handleFilterModal = (action = null) => {
    if (action === null) {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else {
      setIsFilterModalOpen(action);
    }
  }

  const handleAddDashboardModal = (action = null) => {
    if (action === null) {
      setIsAddDashboardModalOpen(!isAddDashboardModalOpen);
    } else {
      setIsAddDashboardModalOpen(action);
    }
  }

  const handleFilterApply = (filters) => {
    // Apply filters
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      ...filters
    }));
  }

  const handleActiveMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  }

  const handlePageChange = (action) => {
    setCurrentPage(action);
  }

  const getAccess = async (group) => {
    try {
      const URL = "dashboard/get-access-group/";
      const response = await axiosCMS.post(URL, {
        access: group
      })
      return response.data;
    } catch (error) {
      console.log('Failed to fetch access data', error);
    }
  }

  const getDataAccess = useCallback(debounce(async () => {
    const getPSMAccess = getAccess('PSM');
    const getRegionAccess = getAccess('REGION');
    const getEstateAccess = getAccess('ESTATE');
    const getDivisionAccess = getAccess('DIVISI');
    const getComplexAccess = getAccess('COMPLEX');
    const getAllAccess = getAccess('ALL-ACCESS');
    const listPromise = [getPSMAccess, getRegionAccess, getEstateAccess, getDivisionAccess, getComplexAccess, getAllAccess];
    Promise.all(listPromise)
      .then((values) => {
        const responsePSMAccess = [];
        values[0].data.forEach((psm, index) => {
          responsePSMAccess.push({ acc_id: psm.access_id, acc_label: psm.access_name, acc_name: '' });
        });
        const responseRegionAccess = [];
        values[1].data.forEach((region, index) => {
          responseRegionAccess.push({ acc_id: region.access_id, acc_label: region.access_name, acc_name: '' });
        });
        const responseEstateAccess = [];
        values[2].data.forEach((estate, index) => {
          responseEstateAccess.push({ acc_id: estate.access_id, acc_label: estate.access_name, acc_name: '' });
        });
        const responseDivisionAccess = [];
        values[3].data.forEach((division, index) => {
          responseDivisionAccess.push({ acc_id: division.access_id, acc_label: division.access_name, acc_name: '' });
        });
        const responseComplexAccess = [];
        values[4].data.forEach((complex, index) => {
          responseComplexAccess.push({ acc_id: complex.access_id, acc_label: complex.access_name, acc_name: '' });
        });
        const responseAllAccess = [];
        values[5].data.forEach((allAccess, index) => {
          responseAllAccess.push({ acc_id: allAccess.access_id, acc_label: allAccess.access_name, acc_name: '' });
        });
        setAllAccessList(responseAllAccess);
        setPSMList(responsePSMAccess);
        setRegionList(responseRegionAccess);
        setEstateList(responseEstateAccess);
        setDivisionList(responseDivisionAccess);
        setComplexList(responseComplexAccess);
      })
      .catch((error) => {
        console.log('Failed to fetch data access', error);
        dispatch(addToast({
          type: 'error',
          title: 'Failed to fetch data access',
        }))
      });
  }, 500), [])

  const getDashboards = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    const URL = "dashboard/get/";
    try {
      const response = await axiosCMS.post(URL, filters)
      if (response.status === 200) {
        const result = response.data;
        setDashboards(result.data.result);
        setTotalPages(result.data.total_page);
      }
    } catch (error) {
      console.log('Failed to fetch dashboards data', error);
      dispatch(addToast({
        type: 'error',
        title: 'Failed to fetch dashboards data',
      }))
    }
    setIsLoading(false);
  }, 1000), [])

  useEffect(() => {
    getDashboards(selectedFilters);
  }, [selectedFilters])

  useEffect(() => {
    handleFilterApply({
      page: currentPage,
      sort: selectedSort.sort,
      order_by: selectedSort.order_by
    })
  }, [currentPage, selectedSort])

  useEffect(() => {
    getDataAccess();
  }, [])

  const handleChangeSearch = debounce((filters, search) => {
    const newFilters = { ...filters, search: search };
    setSelectedFilters(newFilters);
  }, 300)

  useEffect(() => {
    handleChangeSearch(selectedFilters, search);
  }, [search])


  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'Dashboard Management'} />
      <div className="dashboard-mg">
        <div className="dashboard-mg__header-container">
          <h1 className="dashboard-mg__header-title">Dashboard Management</h1>
          <div className="dashboard-mg__action-container">
            <div style={{ position: 'relative' }}>
              <input type="text" placeholder="Search" className="dashboard-search" value={search} onChange={(e) => setSearch(e.target.value)} />
              {search
                ? <img src={clearIcon} alt="search icon" className="dashboard-search__icon" onClick={() => setSearch('')} />
                : <img src={searchIcon} alt="search icon" className="dashboard-search__icon" />
              }
            </div>
            <div className="dashboard-filter">
              <button onClick={handleFilterModal} className="btn-base with-icon font-base">
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className="filter-button-icon"
                />
                Filter
              </button>
              <FilterModal isOpen={isFilterModalOpen} onFilterModal={handleFilterModal} onApply={handleFilterApply} />
            </div>
            <button onClick={handleAddDashboardModal} className="btn-primary with-icon font-primary" >
              <img
                src={plusIcon}
                alt="filter icon"
                className="filter-button-icon"
              />
              Add Dashboard
            </button>
            <AddDashboardModal
              isOpen={isAddDashboardModalOpen}
              onAddModal={handleAddDashboardModal}
              allAccessList={allAccessList}
              psmList={psmList}
              regionList={regionList}
              estateList={estateList}
              divisionList={divisionList}
              complexList={complexList}
              getDashboards={() => getDashboards(selectedFilters)}
            />
          </div>
        </div>
        <div className="dashboard-table">
          <div className="privilege-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th>Dashboard Name</th>
                  <th>Description</th>
                  <th>Report ID</th>
                  <th>Dataset ID</th>
                  <th>Platform</th>
                  <th>Report Section</th>
                  <th>Filter Panel</th>
                  <th>Status</th>
                  <th>
                    <div className="group-flex-8 align-center">
                      <span>Last Updated</span>
                      <img
                        src={
                          selectedSort.order_by === "updated_at"
                            ? selectedSort.sort === "asc"
                              ? sortIconAsc
                              : sortIconDesc
                            : sortIcon
                        }
                        className="sort-icon"
                        alt="Sort icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedSort({
                            order_by: "updated_at",
                            sort:
                              selectedSort.order_by === "updated_at"
                                ? selectedSort.sort === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc",
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th style={{ width: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ?
                    <LoadingTable columnSize={10} rowSize={4} />
                    : (
                      dashboards.length > 0 ? dashboards.map(dashboard => (
                        <tr key={dashboard.id}>
                          <td>{dashboard.name}</td>
                          <td>{dashboard.description}</td>
                          <td>{dashboard.report_id}</td>
                          <td>{dashboard.dataset_id}</td>
                          <td>{dashboard.is_warroom ? "Warroom" : "Desktop"}</td>
                          <td>{dashboard.is_panel ? 'Yes' : 'No'}</td>
                          <td>{dashboard.is_filter ? 'Yes' : 'No'}</td>
                          <td>
                            {dashboard.is_active
                              ? <span className="dashboard-badge--active">Active</span>
                              : <span className="dashboard-badge--inactive">Inactive</span>
                            }
                          </td>
                          <td>{dashboard.updated_at && convertFormatedDate(dashboard.updated_at)}</td>
                          <td>
                            <div className="kebab-menu-container">
                              <img
                                src={kebabMenuIcon}
                                alt="kebab menu"
                                onClick={() => handleActiveMenu(dashboard.id)}
                                style={{ cursor: "pointer" }}
                              />
                              {activeMenuIndex === dashboard.id && (
                                <OutsideHandler handleClose={handleActiveMenu}>
                                  <div className="dashboard-menu">
                                    <div
                                      className="dashboard-menu-option"
                                      onClick={() => setIsEditDashboardModalOpen(dashboard)}
                                    >
                                      <EditIcon className="dashboard-menu-icon" />
                                      Edit
                                    </div>
                                    {/* <div
                                      className="dashboard-menu-option"
                                      onClick={() => setIsDeleteDashboardModalOpen(dashboard)}
                                    >
                                      <TrashIcon className="dashboard-menu-icon" />
                                      Delete
                                    </div> */}
                                  </div>
                                </OutsideHandler>
                              )}
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={6} className="text-center">No data available</td>
                        </tr>
                      )
                    )
                }
              </tbody>
            </table>
          </div>
          <Pagination currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} />
        </div>
        <EditDashboardModal
          dashboardId={isEditDashboardModalOpen}
          onEditModal={setIsEditDashboardModalOpen}
          allAccessList={allAccessList}
          psmList={psmList}
          regionList={regionList}
          estateList={estateList}
          divisionList={divisionList}
          complexList={complexList}
          getDashboards={() => getDashboards(selectedFilters)}
        />
        <DeleteDashboardModal
          dashboard={isDeleteDashboardModalOpen}
          onDeleteModal={setIsDeleteDashboardModalOpen}
          getDashboards={() => getDashboards(selectedFilters)}
        />
      </div>
    </>
  )
}

export default DashboardManagement;