import { useCallback, useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import LoadingComponent from "../../components/Loading";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import plusIcon from "../../assets/plusIconWhite20.svg";
import searchIcon from "../../assets/searchIcon20.svg";
import clearIcon from "../../assets/xIcon20.svg";
import "./UserManagement.css";
import Pagination from "../../components/Pagination";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import FilterModal from "./FilterModal";
import debounce from "../../utils/Debounce";

import { ReactComponent as TrashIcon } from "../../assets/trashIcon16.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";

import AddUserModal from "./AddUserModal";
import kebabMenu from "../../assets/kebabMenu.svg";
import OutsideHandler from "../../components/OutsideHandler";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";
import LoadingTable from "../../components/LoadingTable";

const UserManagement = () => {
  useDocumentTitle('User Management')

  const prevRoutes = [
    { name: 'User&Role Management', route: null }
  ]

  const [users, setUsers] = useState([
    { id: 1, name: 'John Doe', email: 'johndoe@example.com', role: 'Admin', status: 'Active', lastUpdated: '2021-09-01' },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({

  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedSort, setSelectedSort] = useState({
    order_by: "updated_at",
    sort: "asc",
  });
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);


  const handleFilterApply = (filters) => {
    // Apply filters
    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...filters }));
  }

  const handleFilterModal = (action = null) => {
    if (action === null) {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else {
      setIsFilterModalOpen(action);
    }
  }

  const handleAddUserModal = (action = null) => {
    if (action === null) {
      setIsAddUserModalOpen(!isAddUserModalOpen);
    } else {
      setIsAddUserModalOpen(action);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleActiveMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  };

  const getUsers = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    // try {
    //   const URL = "";
    //   const response = await axios.get(URL);
    //   // Fetch users
    // } catch (error) {
    //   console.errpr('Error fetching users', error);
    // }
    console.log('hit fetch users');
    setIsLoading(false);
  }, 1000), [])

  useEffect(() => {
    getUsers(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    handleFilterApply({
      page: currentPage,
      order_by: selectedSort.order_by,
      sort: selectedSort.sort
    })
  }, [currentPage, selectedSort]);

  const handleChangeSearch = debounce((search) => {
    handleFilterApply({ search: search })
  }, 300)

  useEffect(() => {
    handleChangeSearch(search);
  }, [search])

  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'User Management'} />
      <div className="user-mg">
        <div className="user-mg__header-container">
          <h1 className="user-mg__header-title">User Management</h1>
          <div className="user-mg__action-container">
            <div style={{ position: 'relative' }}>
              <input type="text" placeholder="Search" className="user-search" value={search} onChange={(e) => setSearch(e.target.value)} />
              {search
                ? <img src={clearIcon} alt="search icon" className="user-search__icon" onClick={() => setSearch('')} />
                : <img src={searchIcon} alt="search icon" className="user-search__icon" />
              }
            </div>
            <div className="user-filter">
              <button onClick={handleFilterModal} className="btn-base with-icon font-base">
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className="filter-button-icon"
                />
                Filter
              </button>
              <FilterModal isOpen={isFilterModalOpen} onFilterModal={handleFilterModal} onApply={handleFilterApply} />
            </div>
            <button onClick={handleAddUserModal} className="btn-primary with-icon font-primary" >
              <img
                src={plusIcon}
                alt="filter icon"
                className="filter-button-icon"
              />
              Add User
            </button>
            <AddUserModal isOpen={isAddUserModalOpen} onAddUserModal={handleAddUserModal} />
          </div>
        </div>
        <div className="user-table">
          <div className="user-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>CMS Access</th>
                  <th>Menu Privilege</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>
                    <div className="group-flex-8 align-center">
                      <span>Last Updated</span>
                      <img
                        src={
                          selectedSort.order_by === "updated_at"
                            ? selectedSort.sort === "asc"
                              ? sortIconAsc
                              : sortIconDesc
                            : sortIcon
                        }
                        className="sort-icon"
                        alt="Sort icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedSort({
                            order_by: "updated_at",
                            sort:
                              selectedSort.order_by === "updated_at"
                                ? selectedSort.sort === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc",
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th style={{ width: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ?
                    <LoadingTable columnSize={8} rowSize={4} />
                    : (
                      users.length > 0 ? users.map(user => (
                        <tr key={user.id}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.cms_access}</td>
                          <td>{user.menu_priv}</td>
                          <td>{user.role}</td>
                          <td><span className="user-badge--active">{user.status}</span></td>
                          <td>{user.lastUpdated}</td>
                          <td>
                            <div className="kebab-menu-container">
                              <img
                                src={kebabMenu}
                                alt="kebab menu"
                                onClick={() => handleActiveMenu(user.id)}
                                style={{ cursor: "pointer" }}
                              />
                              {activeMenuIndex === user.id && (
                                <OutsideHandler handleClose={handleActiveMenu}>
                                  <div className="user-menu">
                                    <div
                                      className="user-menu-option"
                                      onClick={() => setIsEditUserModalOpen(user)}
                                    >
                                      <EditIcon className="user-menu-icon" />
                                      Edit
                                    </div>
                                    <div
                                      className="user-menu-option"
                                      onClick={() => setIsDeleteUserModalOpen(user)}
                                    >
                                      <TrashIcon className="user-menu-icon" />
                                      Delete
                                    </div>
                                  </div>
                                </OutsideHandler>
                              )}
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={7} className="text-center">No data available</td>
                        </tr>
                      )
                    )
                }
              </tbody>
            </table>
          </div>
          <Pagination currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} />
        </div>
        <EditUserModal user={isEditUserModalOpen} onClose={() => setIsEditUserModalOpen(null)} />
        <DeleteUserModal user={isDeleteUserModalOpen} onDeleteModal={() => setIsDeleteUserModalOpen(null)} />
      </div>
    </>
  )
}

export default UserManagement;