import { useCallback, useEffect, useState } from "react";
import debounce from "../../utils/Debounce";
import LoadingComponent from "../../components/Loading";
import axiosCMS from "../../libs/axios/axiosCMS";
import LoadingTable from "../../components/LoadingTable";

const TCCAnalyticTable = ({ selectedFilters }) => {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getFunctionData = useCallback(debounce(async (filters) => {
    setIsLoading(true)
    try {
      const URL = "token-consumption/table-function-token-consumption";
      const response = await axiosCMS.post(URL, filters);
      if (response.status === 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false)
  }, 1500), [])

  useEffect(() => {
    getFunctionData(selectedFilters);
  }, [selectedFilters])

  return (
    <div className="tcc-analytic-table">
      <table>
        <thead>
          <tr>
            <th>Function</th>
            <th>Avg. Token Consumption</th>
            <th>Avg. Token Cost Consumption</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? <LoadingTable columnSize={3} rowSize={4} />
            : data.length === 0 ? (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>No data available</td>
              </tr>
            ) :
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.function}</td>
                  <td>{item.avg_token_consumption}</td>
                  <td>{item.avg_token_cost_consumption}</td>
                </tr>
              ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default TCCAnalyticTable;