import alertIcon from "../assets/alertIconPrimary16.svg";

const InputErrorMessage = ({ message }) => {
  return (
    <div className="input-error">
      <img src={alertIcon} alt="alert-icon" />
      <p>{message}</p>
    </div>
  )
}

export default InputErrorMessage;