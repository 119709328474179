import { useCallback, useEffect, useState } from "react"
import debounce from "../../utils/Debounce"
import axios from "axios"
import LoadingOverview from "../../components/LoadingOverview";
import { numberWithCommas } from "../../utils/FunctionHelpers";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axiosCMS from "../../libs/axios/axiosCMS";

const TCCOverview = ({ selectedFilters, selectedMonth, selectedYear }) => {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const getData = useCallback(debounce(async (filters) => {
    setIsLoading(true)
    try {
      const URL = 'token-consumption/overview';
      const response = await axiosCMS.post(URL, filters);
      if (response.status == 200) {
        const result = response.data
        setData(result.data[0])
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false)
  }, 1000), [])

  useEffect(() => {
    getData(selectedFilters)
  }, [selectedFilters])

  return (
    <div className="tcc-section">
      <h2 className="section-title">Overview</h2>
      {
        isLoading
          ? <LoadingOverview length={4} prefixCSS="tcc" />
          : (
            <div className="tcc-boxes">
              <div className="tcc-box">
                <div className="box-title">
                  Total Token Consumption
                  <img
                    src={tooltipIcon}
                    alt="tooltip icon"
                    className="tooltip-icon anchor-total-token-consumption"
                  />
                  <ReactTooltip
                    id='tooltip'
                    place="top"
                    content="The total number of token consumption"
                    anchorSelect=".anchor-total-token-consumption"
                    style={{ maxWidth: "150px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                  />
                </div>
                <p className="box-value">{data.total_token_consumption ? numberWithCommas(data.total_token_consumption.total) : 0}</p>
                <p className="box-date">
                  {
                    (selectedMonth.length === 1 && selectedYear.length === 1) &&
                    <>
                      <span
                        style={{ color: data.total_token_consumption && data.total_token_consumption.growth >= 0 ? 'green' : 'red' }}
                      >
                        {data.total_token_consumption
                          ? data.total_token_consumption.growth >= 0 ? `+${data.total_token_consumption.growth.toFixed(2)}` : data.total_token_consumption.growth.toFixed(2)
                          : 0
                        }% {""}
                      </span>
                      from last month
                    </>
                  }
                </p>
              </div>
              <div className="tcc-box">
                <div className="box-title">
                  Total Token Cost Consumption
                  <img
                    src={tooltipIcon}
                    alt="tooltip icon"
                    className="tooltip-icon anchor-total-cost-consumption"
                  />
                  <ReactTooltip
                    id='tooltip'
                    place="top"
                    content="The total cost of token consumption"
                    anchorSelect=".anchor-total-cost-consumption"
                    style={{ maxWidth: "150px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                  />
                </div>
                <p className="box-value"><span className="dolar-text">$ </span>{data.total_token_cost_consumption ? data.total_token_cost_consumption.total.toFixed(2) : 0}</p>
                <p className="box-date">
                  {
                    (selectedMonth.length === 1 && selectedYear.length === 1) &&
                    <>
                      <span
                        style={{ color: data.total_token_cost_consumption && data.total_token_cost_consumption.growth >= 0 ? 'green' : 'red' }}
                      >
                        {data.total_token_cost_consumption
                          ? data.total_token_cost_consumption.growth >= 0 ? `+${data.total_token_cost_consumption.growth.toFixed(2)}` : data.total_token_cost_consumption.growth.toFixed(2)
                          : 0
                        }% {""}
                      </span>
                      from last month
                    </>
                  }
                </p>
              </div>
              <div className="tcc-box">
                <div className="box-title">
                  Avg. Token Consumption per Chat
                  <img
                    src={tooltipIcon}
                    alt="tooltip icon"
                    className="tooltip-icon anchor-token-consumption-chat"
                  />
                  <ReactTooltip
                    id='tooltip'
                    place="top"
                    content="The average number of tokens used per chat"
                    anchorSelect=".anchor-token-consumption-chat"
                    style={{ maxWidth: "150px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                  />
                </div>
                <p className="box-value">{data.avg_token_consumption?.total.toFixed(2) ?? 0}</p>
                <p className="box-date">
                  {
                    (selectedMonth.length === 1 && selectedYear.length === 1) &&
                    <>
                      <span
                        style={{ color: data.avg_token_consumption && data.avg_token_consumption.growth >= 0 ? 'green' : 'red' }}
                      >
                        {data.avg_token_consumption
                          ? data.avg_token_consumption.growth >= 0 ? `+${data.avg_token_consumption.growth.toFixed(2)}` : data.avg_token_consumption.growth.toFixed(2)
                          : 0
                        }% {""}
                      </span>
                      from last month
                    </>
                  }
                </p>
              </div>
              <div className="tcc-box">
                <div className="box-title">
                  Avg. Token Cost Consumption per Chat
                  <img
                    src={tooltipIcon}
                    alt="tooltip icon"
                    className="tooltip-icon anchor-token-cost-consumption-chat"
                  />
                  <ReactTooltip
                    id='tooltip'
                    place="top"
                    content="The average cost of token consumption per chat"
                    anchorSelect=".anchor-token-cost-consumption-chat"
                    style={{ maxWidth: "150px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
                  />
                </div>
                <p className="box-value"><span className="dolar-text">$ </span>{data.avg_token_cost_consumption ? data.avg_token_cost_consumption.total.toFixed(3) : 0}</p>
                <p className="box-date">
                  {
                    (selectedMonth.length === 1 && selectedYear.length === 1) &&
                    <>
                      <span
                        style={{ color: data.avg_token_cost_consumption && data.avg_token_cost_consumption.growth >= 0 ? 'green' : 'red' }}
                      >
                        {data.avg_token_cost_consumption
                          ? data.avg_token_cost_consumption.growth >= 0 ? `+${data.avg_token_cost_consumption.growth.toFixed(2)}` : data.avg_token_cost_consumption.growth.toFixed(2)
                          : 0
                        }% {""}
                      </span>
                      from last month
                    </>
                  }
                </p>
              </div>
            </div>
          )
      }
    </div>
  )
}

export default TCCOverview