import { useCallback, useEffect, useState } from "react";
import debounce from "../../utils/Debounce";
import LoadingComponent from "../../components/Loading";
import axiosCMS from "../../libs/axios/axiosCMS";
import LoadingTable from "../../components/LoadingTable";

const FunctionTable = ({ selectedFilters }) => {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getData = useCallback(debounce(async (filters) => {
    setIsLoading(true)
    try {
      const URL = "response-quality/table-analytic";
      const response = await axiosCMS.post(URL, filters);
      if (response.status == 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching function table data:', error);
    }
    setIsLoading(false)
  }, 1500), [])

  useEffect(() => {
    getData(selectedFilters);
  }, [selectedFilters])

  return (
    <div className="rq-function-table">
      <table>
        <thead>
          <tr>
            <th>Function</th>
            <th>Response Accuracy (%)</th>
            <th>Total Thumbs Up</th>
            <th>Total Thumbs Down</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? <LoadingTable columnSize={4} rowSize={4} />
            : data.length === 0 ?
              <tr>
                <td colSpan={4} style={{ textAlign: 'center' }}>No data available</td>
              </tr>
              : data.map((item, index) => (
                <tr key={index}>
                  <td>{item.function}</td>
                  <td>{item.response_accuracy.rate.toFixed(2)}% ({item.response_accuracy.total} response)</td>
                  <td>{item.thumbs.thumbs_up} ({item.thumbs.total_thumbs} Feedbacks)</td>
                  <td>{item.thumbs.thumbs_down} ({item.thumbs.total_thumbs} Feedbacks)</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  )
}

export default FunctionTable;