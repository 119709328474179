import axios from "axios";

const BASE_URL = 'https://genai-cms-api.nawatech.co/api/sma/';
// const BASE_URL = 'http://localhost:8000/api/sma/';
const axiosCMS = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
});

axiosCMS.interceptors.response.use((response) => {
    return response;
}, (error) => {
    return Promise.reject(error);
});

axiosCMS.interceptors.request.use((config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});


export default axiosCMS;