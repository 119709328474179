import { useDispatch } from "react-redux";
import closeIcon from "../../assets/cancelIcon.svg";
import { addToast } from "../../libs/redux/slices/toastSlice";

const DeleteMenuModal = ({ isOpen, menu, onDeleteModal }) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    console.log("Menu deleted successfully");
    onDeleteModal(null);
    dispatch(addToast({
      type: 'success',
      title: 'Data has been deleted',
    }))
  }

  if (!isOpen) return null;
  return (
    <div className="privilege-add__overlay">
      <div className="privilege-add__modal">
        <div className="privilege-add__header">
          <h2 className="privilege-add__title">Delete Menu</h2>
          <button className="privilege-add__close" onClick={() => onDeleteModal(null)}>
            <img src={closeIcon} alt="close icon" />
          </button>
        </div>
        <div className="privilege-add__separator-line"></div>
        <div className="privilege-add__content">
          <h3 className="privilege-add__confirmation-title">Do you really wish to delete selected menu?</h3>
          <p className="privilege-add__confirmation-desc">Once you delete, the selected menu will be removed to the system.</p>
          <div className="privilege-add__footer">
            <button className="btn-base font-base" onClick={() => onDeleteModal(false)}>Cancel</button>
            <button className="btn-primary font-primary" onClick={handleSubmit}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteMenuModal;