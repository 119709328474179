import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Tooltip,
  BarController,
  LogarithmicScale,
} from 'chart.js';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Chart, getElementsAtEvent } from 'react-chartjs-2';
import axios from 'axios';
import debounce from '../../utils/Debounce';
import LoadingChart from '../../components/LoadingChart';
import tooltipIcon from "../../assets/tooltipIcon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axiosCMS from '../../libs/axios/axiosCMS';

ChartJS.register(CategoryScale, LinearScale, LogarithmicScale, BarElement, BarController, PointElement, Tooltip);

const TopFunctionByQueryChart = ({ selectedFilters }) => {
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [listColors, setListColors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const generateColor = (ratio) => {
    let r = 209; // Mengurangi nilai merah secara bertahap
    let g = 90;       // Meningkatkan nilai hijau secara bertahap
    let b = 90;
    if (ratio < 0.5) {
      // Dari merah ke kuning
      r = 209;
      g = Math.round(2 * ratio * 90 + 90); // Mengatur hijau dari 0 ke 255
    } else {
      // Dari kuning ke hijau
      r = Math.round(209 - (119 * (ratio - 0.5) * 2)); // Mengurangi merah dari 255 ke 0
      g = 209;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }

  const generateListColors = (dataLength) => {
    const colors = [];
    if (dataLength === 0) return;
    if (dataLength === 1) {
      colors.push('rgb(209, 90, 90)');
    } else {
      for (let i = 0; i < dataLength; i++) {
        const ratio = i / (dataLength - 1);
        colors.push(generateColor(ratio));
      }
    }
    setListColors(colors);
  }

  const generateFocusListColor = (dataLength, focusIndex) => {
    const colors = [];

    for (let i = 0; i < dataLength; i++) {
      const ratio = i / (dataLength - 1);
      if (i === focusIndex) {
        colors.push(generateColor(ratio));
      } else {
        colors.push('rgb(218, 224, 222)');
      }
    }
    return colors;
  }


  const getChartData = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    try {
      const URL = 'user-engagement/top-function';
      const response = await axiosCMS.post(URL, filters)
      const result = response.data;
      setChartData(result.data.map((data) => data.total));
      setChartLabels(result.data.map((data) => data.function));
    } catch (error) {
      console.error('Failed to fetch data top function by query chart: ', error);
    }
    setIsLoading(false);
  }, 1500), []);

  useEffect(() => {
    getChartData(selectedFilters);
  }, [selectedFilters])

  useEffect(() => {
    generateListColors(chartLabels.length);
  }, [chartLabels]);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        type: 'bar',
        backgroundColor: listColors,
        data: chartData,
        maxBarThickness: 70,
      },
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
        type: 'logarithmic',
      }
    },
  };

  const chartRef = useRef(null)
  const handleEventChart = debounce((event) => {
    const element = getElementsAtEvent(chartRef.current, event);
    const chart = chartRef.current;
    if (element.length > 0) {
      chart.data.datasets[0].backgroundColor = generateFocusListColor(chartLabels.length, element[0].index);
    } else {
      chart.data.datasets[0].backgroundColor = listColors;
    }
    chart.update();
  }, 200);

  return (
    <div className='ue-section'>
      <h3 className="section-title">
        Top Function by Query
        <img
          src={tooltipIcon}
          alt="tooltip icon"
          className="tooltip-icon anchor-top-function-by-query"
        />
        <ReactTooltip
          id='tooltip'
          place="top"
          content="Most frequently queried Function by users, sorted from highest to lowest"
          anchorSelect=".anchor-top-function-by-query"
          style={{ maxWidth: "200px", fontSize: "12px", fontWeight: "400", lineHeight: "1.2", textAlign: "center" }}
        />
      </h3>
      <div style={{ position: 'relative', height: '300px' }}>
        {isLoading &&
          <LoadingChart />
        }
        <Chart type='bar' data={data} onClick={handleEventChart} options={chartOptions} ref={chartRef} />
      </div>
    </div>
  )
}

export default TopFunctionByQueryChart;